import { useEffect, useRef, useState } from 'react';

const useIdleTimeoutV2 = (idleThreshold) => {
  const [isUserIdle, setIsUserIdle] = useState(false);

  const idleTimeoutRef = useRef();

  const handleIdleTimeout = () => {
    setIsUserIdle(true);
  };

  const setTimeouts = () => {
    idleTimeoutRef.current = setTimeout(handleIdleTimeout, idleThreshold);
  };

  const clearTimeouts = () => {
    if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
  };

  const resetTimeout = () => {
    clearTimeouts();
    setTimeouts();
    setIsUserIdle(false);
  };

  useEffect(() => {
    const events = [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ];

    for (let index = 0; index < events.length; index += 1) {
      window.addEventListener(events[index], resetTimeout);
    }

    setTimeouts();

    return () => {
      for (let index = 0; index < events.length; index += 1) {
        window.removeEventListener(events[index], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  return { isUserIdle, resetTimeout };
};

export default useIdleTimeoutV2;
