import React from 'react';
import './Unauthorized.css';

function Unauthorized() {
  return (
    <div>
      Unauthorized
    </div>
  );
}

export default Unauthorized;
