export const SystemActionTypes = {
  SET_USERS_LIST: 'SET_USERS_LIST',
  SET_SOURCE_LIST: 'SET_SOURCE_LIST',
  SET_APP_STATUS_LIST: 'SET_APP_STATUS_LIST',
  SET_CLASSIFICATION_LIST: 'SET_CLASSIFICATION_LIST',
  SET_SUB_CLASSIFICATION_LIST: 'SET_SUB_CLASSIFICATION_LIST',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_LINE_OF_BUSINESS_LIST: 'SET_LINE_OF_BUSINESS_LIST',
  SET_CARRIER_LIST: 'SET_CARRIER_LIST',
  LOADING_SYSTEM_CALL: 'LOADING_SYSTEM_CALL',
  RESET_SYSTEM_CALL: 'RESET_SYSTEM_CALL',
};
