// importing libraries
import React, { lazy, Suspense } from 'react';
import {
  Routes as ViewsRoutes, BrowserRouter, Route, Navigate,
} from 'react-router-dom';

// importing components
import Error from '../components/molecules/error/Error';
import Unauthorized from '../components/molecules/unauthorized/Unauthorized';
import MainLayout from '../layouts/main/MainLayout';
import ProtectedOutlet from './ProtectedOutlet';
import { UserAuthContextProvider } from '../context/UserAuthContext';
import WorkflowHeader from '../components/molecules/workflow-header/WorkflowHeader';

// Lazy Routes for the screen
const LazyLogin = lazy(() => import('../views/login/Login'));
const LazyTermsConditions = lazy(() => import('../views/terms-conditions/TermsConditions'));
const LazyCreateApplication = lazy(() => import('../views/create-application/CreateApplication'));
const LazyApplicationDashboard = lazy(() => import('../views/application-dashboard/ApplicationDashboard'));
const LazyAuditLogs = lazy(() => import('../views/audit-logs/AuditLogs'));
const LazyAccessManagement = lazy(() => import('../views/access-management-dashboard/AccessManagementDashboard'));
const PermissionManager = lazy(() => import('../views/access-management-dashboard/components/permission-manager/PermissionManager'));
const LazyExtractionScreen = lazy(() => import('../views/extraction-screen/ExtractionScreen'));
const LazyComparisonScreen = lazy(() => import('../views/comparison-screen/ComparisonScreen'));
const LazyInsightsDashboard = lazy(() => import('../views/insights-dashboard/InsightsDashboard'));

function Routes() {
  return (
    <BrowserRouter>
      <UserAuthContextProvider>
        <ViewsRoutes>
          {/* Public Routes */}
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="error" element={<Error />} />
          <Route path="unauthorized" element={<Unauthorized />} />

          <Route
            path="/login"
            element={(
              <Suspense fallback={<>...</>}>
                <LazyLogin />
              </Suspense>
            )}
          />
          {/* Protected Routes */}
          <Route element={<ProtectedOutlet />}>
            <Route
              path="/terms"
              element={(
                <Suspense fallback={<>...</>}>
                  <LazyTermsConditions />
                </Suspense>
              )}
            />
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Navigate to="/home/application-dashboard" />} />
              <Route
                path="/home"
                element={<Navigate to="/home/application-dashboard" replace />}
              />
              <Route
                path="/home/create-application"
                element={(
                  <Suspense fallback={<>...</>}>
                    <LazyCreateApplication />
                  </Suspense>
                )}
              />
              <Route
                path="/home/application-dashboard"
                element={(
                  <Suspense fallback={<>...</>}>
                    <LazyApplicationDashboard />
                  </Suspense>
                )}
              />
              <Route
                path="/home/access-management"
                element={(
                  <Suspense fallback={<>...</>}>
                    <LazyAccessManagement />
                  </Suspense>
                )}
              />
              <Route
                path="/home/access-management/permission-manager/:roleId"
                element={(
                  <Suspense fallback={<>...</>}>
                    <PermissionManager />
                  </Suspense>
                )}
              />
              <Route
                path="/home/audit-logs"
                element={(
                  <Suspense fallback={<>...</>}>
                    <LazyAuditLogs />
                  </Suspense>
                )}
              />
              <Route
                path="/home/insights-dashboard"
                element={(
                  <Suspense fallback={<>...</>}>
                    <LazyInsightsDashboard />
                  </Suspense>
                )}
              />
              <Route path="/home/applications/:applicationId" element={<WorkflowHeader />}>
                <Route
                  path="/home/applications/:applicationId/extraction"
                  element={(
                    <Suspense fallback={<>...</>}>
                      <LazyExtractionScreen />
                    </Suspense>
                  )}
                />

                <Route
                  path="/home/applications/:applicationId/comparison"
                  element={(
                    <Suspense fallback={<>...</>}>
                      <LazyComparisonScreen />
                    </Suspense>
                  )}
                />
              </Route>
            </Route>
          </Route>
        </ViewsRoutes>
      </UserAuthContextProvider>
    </BrowserRouter>
  );
}
export default Routes;
