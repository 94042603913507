import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from '../context/SnackbarContext';
import { UserManagement } from '../services/constants/endpoints';
import useAxios from './useAxios';
import { setPermissions } from '../redux/actions/systemActions';
import { ApplicationStatus, Permissions } from '../services/constants/constants';
import { useUserAuth } from '../context/UserAuthContext';

const usePermissions = () => {
  const axiosPrivate = useAxios();
  const { permissions, users } = useSelector((state) => state?.system);
  const { isTimerPaused } = useSelector((state) => state?.timer);
  const { openedApplication } = useSelector((state) => state?.applications);
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const { user } = useUserAuth();

  const fetchUserPermissions = (userId) => axiosPrivate
    .get(UserManagement.GET_USER_PERMISSIONS(userId))
    .then((response) => {
      dispatch(setPermissions(response.data.data.permissions));
    })
    .catch((error) => {
      openSnackbar('error', error?.response?.data?.message);
    });

  const checkPermission = (permission) => {
    if (permissions?.includes(permission)) {
      return true;
    }
    return false;
  };

  const isUserAssigned = () => {
    // checks if the current user is assigned to this application
    if (openedApplication?.assignment?.assigned_to) {
      if (user.email === users
        .find((usr) => usr.key === openedApplication.assignment.assigned_to)?.email) {
        return true;
      }
      return false;
    }
    return false;
  };

  const shouldDisableHitl = () => {
    if (checkPermission(Permissions.EDIT_EXTRACTION)) {
      if (isUserAssigned()) {
        if (isTimerPaused) {
          return true;
        }
        return false;
      }
      return true;
    }
    return true;
  };

  const shouldShowHitl = () => {
    if (
      openedApplication?.status
      && openedApplication?.status?.toLowerCase() === ApplicationStatus.REVIEWED.toLowerCase()
    ) {
      return false;
    }
    return true;
  };

  return {
    fetchUserPermissions, checkPermission, shouldDisableHitl, shouldShowHitl, isUserAssigned,
  };
};

export default usePermissions;
