// importing libraries
import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

// importing components
import useAxios from './useAxios';
import { useSnackbar } from '../context/SnackbarContext';
import {
  removeSelectedDocumentIds,
  setSelectedDocumentIds,
  removeComparisonDetails,
  setComparisonDetails,
  clearSearchText,
  removeCmparisonTableWidth,
} from '../redux/actions/comparisonActions';
import {
  ApplicationService,
  ComparisonService,
} from '../services/constants/endpoints';
import { setAllBbox } from '../redux/actions/boundingBoxActions';
import Toast from '../services/constants/toasters';

function useComparison(applicationId) {
  // custom hooks
  const axiosPrivate = useAxios();
  const { openSnackbar } = useSnackbar();

  // pre-build hooks
  const dispatch = useDispatch();
  const allCoordinates = useRef([]);

  // global state
  const documentIds = useSelector(
    (state) => state.comparison.selectedDocumentIds,
  );

  // local state
  const [loading, setLoading] = useState(false);
  const [documentUrls, setDocumentUrls] = useState([]);

  const storeSelectedDocumentIds = (documentId) => {
    dispatch(setSelectedDocumentIds(documentId));
  };

  const resetSelectedDocumentIds = () => {
    dispatch(removeSelectedDocumentIds());
  };

  const fetchAllDocumentUrls = (docs) => {
    const getDocsReq = [];

    for (let docIndex = 0; docIndex < docs.length; docIndex += 1) {
      getDocsReq.push(
        axiosPrivate.get(
          ApplicationService.GET_PRESIGNED_URL(applicationId, docs[docIndex].id),
        ),
      );
    }

    setLoading(true);
    axios
      .all(getDocsReq)
      .then(
        axios.spread((...responses) => {
          const data = [];

          for (let resIndex = 0; resIndex < responses.length; resIndex += 1) {
            const response = responses[resIndex];
            data.push({
              url: response.data.data.url,
              id: docs[resIndex].id,
              name: docs[resIndex].name,
              chip: docs[resIndex].metadata.classification,
            });
          }
          setDocumentUrls(data);
        }),
      )
      .catch((err) => {
        openSnackbar('error', err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  const getSectionBBox = (section, documents) => {
    section.keys.forEach((keyObj) => {
      const docIndex = documents.findIndex(
        (doc) => doc.id === keyObj.document_id,
      );
      if (docIndex !== -1) {
        keyObj.bounding_box.forEach((bbox) => {
          allCoordinates.current.push({
            id: uuidv4(),
            doc_index: docIndex,
            page_number: +keyObj.page_number,
            coordinates: bbox,
          });
        });
      }
    });

    section.values.forEach((valueObj, valueIndex) => {
      valueObj.bounding_box.forEach((bbox) => {
        allCoordinates.current.push({
          id: uuidv4(),
          doc_index: valueIndex,
          page_number: +valueObj.page_number,
          coordinates: bbox,
        });
      });
    });

    section.data.forEach((dataObj) => {
      getSectionBBox(dataObj, documents);
    });
  };

  const getAllBoundingBoxes = (sections, documents) => {
    for (
      let sectionIndex = 0;
      sectionIndex < sections.length;
      sectionIndex += 1
    ) {
      const section = sections[sectionIndex];

      getSectionBBox(section, documents);
    }
  };

  const fetchComparisonDetails = async (
    presignedLoadRequired = true,
    isSilent = false,
    config = null,
  ) => {
    const payload = {
      documents: documentIds,
    };
    if (!isSilent) {
      setLoading(true);
    }
    return axiosPrivate
      .post(ComparisonService.VIEW_COMPARISON(applicationId), payload, config)
      .then((response) => {
        if (presignedLoadRequired) {
          fetchAllDocumentUrls(response.data.data.documents);
        }
        getAllBoundingBoxes(
          response.data.data.sections,
          response.data.data.documents,
        );
        dispatch(setAllBbox(allCoordinates.current));
        dispatch(setComparisonDetails(response.data.data));
        return true;
      })
      .catch((error) => {
        if (!isSilent) {
          if (error?.message !== 'canceled') {
            openSnackbar('error', Toast.COMPARISON_FETCHING_FAILED);
          }
        }
        return false;
      })
      .finally(() => setLoading(false));
  };

  const removeComparisonDetail = () => {
    dispatch(removeComparisonDetails());
  };

  const hitlOperationMethod = async (entityType, action, values) => {
    try {
      setLoading(true);
      const keyValueHitlEndpoint = ComparisonService.KEY_HITL_OPERATION(applicationId);
      const tableHitlEndpoint = ComparisonService.TABLE_HITL_OPERATION(applicationId);
      const selectEndpoint = entityType === 'table' ? tableHitlEndpoint : keyValueHitlEndpoint;
      const payload = {
        action,
        values,
      };
      const response = await axiosPrivate.post(selectEndpoint, payload);
      if (response.data.status) {
        return true;
      }
      setLoading(false);
      return false;
    } catch (error) {
      openSnackbar('error', Toast.COMPARISON_DATA_UPDATE_FAILED);
      setLoading(false);
      return false;
    }
  };

  const exportComparison = (format) => {
    setLoading(true);
    axiosPrivate
      .get(
        `${ComparisonService.EXPORT_COMPARISON(applicationId, format)}`,
      )
      .then((response) => {
        const downloadUrl = response.data.data.url;
        return axiosPrivate.get(downloadUrl, { responseType: 'blob' });
      })
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${applicationId}-comparison.${format}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        openSnackbar('success', 'Document downloaded successfully');
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
      });
  };

  const resetSearchText = () => {
    dispatch(clearSearchText());
  };

  const updateMatch = async (payload) => {
    try {
      await axiosPrivate.post(`${ComparisonService.UPDATE_MATCHED(applicationId)}`, payload);
      return true;
    } catch (error) {
      openSnackbar('error', Toast.COMPARISON_DATA_UPDATE_FAILED);
      return false;
    }
  };

  const updateCellsForValidation = async (payload) => {
    try {
      setLoading(true);
      await axiosPrivate.patch(`${ComparisonService.UPDATE_CELLS_FOR_VALIDATION(applicationId)}`, payload);
      setLoading(false);
      return true;
    } catch (error) {
      openSnackbar('error', Toast.COMPARISON_DATA_UPDATE_FAILED);
      setLoading(false);
      return false;
    }
  };

  const resetCmparisonTableWidth = () => {
    dispatch(removeCmparisonTableWidth());
  };

  return {
    storeSelectedDocumentIds,
    resetSelectedDocumentIds,
    fetchAllDocumentUrls,
    fetchComparisonDetails,
    removeComparisonDetail,
    hitlOperationMethod,
    documentUrls,
    setDocumentUrls,
    loading,
    resetSearchText,
    exportComparison,
    updateMatch,
    updateCellsForValidation,
    resetCmparisonTableWidth,
  };
}

export default useComparison;
