import { ExtractionDropdown } from '../../services/constants/constants';
import { ExtractionActionTypes } from '../constants/extractionActionTypes';

const initialState = {
  selectedDocumentId: '',
  extraction: null,
  excelTable: [],
  currentPage: 1,
  isDataSkipped: false,
  entityCount: 0,
  tableCount: 0,
  summary: null,
  summaryEntityCount: 0,
  summaryTableCount: 0,
  selectedEntityValue: ExtractionDropdown.pagination,
};

const extractionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ExtractionActionTypes.SET_SELECTED_EXTRACTION:
      return { ...state, extraction: payload };

    case ExtractionActionTypes.SET_EXCEL_TABLE_DATA:
      return { ...state, excelTable: payload };

    case ExtractionActionTypes.REMOVE_SELECTED_EXTRACTION:
      return { ...state, extraction: null };

    case ExtractionActionTypes.SET_SELECTED_DOCUMENT_ID:
      return { ...state, selectedDocumentId: payload };

    case ExtractionActionTypes.REMOVE_SELECTED_DOCUMENT_ID:
      return { ...state, selectedDocumentId: '' };

    case ExtractionActionTypes.SET_EXTRACTION_CURRENT_PAGE:
      return { ...state, currentPage: payload };

    case ExtractionActionTypes.RESET_CURRENT_PAGE:
      return { ...state, currentPage: 1 };

    case ExtractionActionTypes.SET_IS_DATA_SKIPPED:
      return { ...state, isDataSkipped: payload };

    case ExtractionActionTypes.SET_ENTITY_COUNT:
      return { ...state, entityCount: payload };

    case ExtractionActionTypes.SET_TABLE_COUNT:
      return { ...state, tableCount: payload };

    case ExtractionActionTypes.RESET_EXTRACTION:
      return initialState;

    case ExtractionActionTypes.SET_DOCUMENT_SUMMARY:
      return { ...state, summary: payload };

    case ExtractionActionTypes.SET_SUMMARY_ENTITY_COUNT:
      return { ...state, summaryEntityCount: payload };

    case ExtractionActionTypes.SET_SUMMARY_TABLE_COUNT:
      return { ...state, summaryTableCount: payload };

    case ExtractionActionTypes.SET_ENTITY_DROPDOWN_SELECTED_VALUE:
      return { ...state, selectedEntityValue: payload };

    case ExtractionActionTypes.REMOVE_ENTITY_DROPDOWN_SELECTED_VALUE:
      return { ...state, selectedEntityValue: ExtractionDropdown.pagination };

    default:
      return state;
  }
};

export default extractionReducer;
