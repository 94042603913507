// importing libraries
import React from 'react';
import { bool, func, string } from 'prop-types';

// importing styles
import classes from './Checkbox.module.css';
import IconButton from '../../molecules/icon-button/IconButton';
import usePermissions from '../../../hooks/usePermissions';

function Checkbox(props) {
  const {
    label, isChecked, handleChange, fontSize, color,
    isDisabled, isTimerDependent,
  } = props;

  const { shouldShowHitl, shouldDisableHitl } = usePermissions();

  const shouldDisable = () => {
    if (isTimerDependent) {
      return isDisabled || shouldDisableHitl();
    }
    return isDisabled;
  };

  const shouldShow = () => {
    if (isTimerDependent) {
      return shouldShowHitl();
    }
    return true;
  };

  return (
    shouldShow() ? (
      <div className={classes.checkbox}>
        <span>
          {
            isChecked
              ? (
                <IconButton
                  iconName="checkedCheckboxIcon"
                  iconWidth="12"
                  iconHeight="12"
                  handleClick={() => handleChange(!isChecked)}
                  isDisabled={shouldDisable()}
                />
              )
              : (
                <IconButton
                  iconName="unCheckedCheckboxIcon"
                  iconWidth="12"
                  iconHeight="12"
                  handleClick={() => handleChange(!isChecked)}
                  isDisabled={shouldDisable()}
                />
              )
          }
        </span>
        <span
          style={{ fontSize, color: `var(--${color})` }}
          onClick={() => handleChange(!isChecked)}
          aria-hidden
        >
          {label}
        </span>
      </div>
    ) : ('')
  );
}

Checkbox.propTypes = {
  label: string,
  isChecked: bool.isRequired,
  handleChange: func.isRequired,
  fontSize: string,
  color: string,
  isDisabled: bool,
  isTimerDependent: bool,
};

Checkbox.defaultProps = {
  label: '',
  fontSize: '0.75rem',
  color: 'darkBlue',
  isDisabled: false,
  isTimerDependent: false,
};

export default Checkbox;
