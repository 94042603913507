const Toast = {
  DEFAULT_SUCCESS_TOAST_MSG: 'Operation performed successfully!',
  DEFAULT_ERROR_TOAST_MSG: 'Something went wrong! Please try again.',

  APPLICATION_CREATED_SUCCESS: 'Application created successfully!',

  // application fetching failed
  FAILED_APPLICATION_MSG: 'Failed to get applications',
  // attachment dashboard toaster constants
  FAILED_ATTACHMENT_MSG: 'Failed to get attachments',
  // failed to assign application
  FAILED_ASSIGNMENT_TO_USER: 'Failed to assign the application',
  // failed to delete application
  FAILED_DELETE_APPLICATION: 'Failed to delete the application.',
  // application deleted successfully
  DELETE_APPLICATION_SUCCESS: 'Application deleted successfully',
  // failed to get presigned url to preview documnet
  FAILED_TO_GET_PRESIGNED_URL: 'Failed to preview the document',
  // failed to confirm classification
  FAILED_TO_CONFIRM_CLF: 'Failed to confirm classificaion',
  // classification confirmed successfully
  CLASSIFICATION_CONFIRMED_CLF: 'Classification(s) confirmed successfully',

  REVIEW_COMPLETED_SUCCESS: 'Review completed successfully!',
  REVIEW_RESTARTED_SUCCESS: 'Review restarted successfully!',
  NO_DATA_FOUND_NEXT_PAGE: 'No data found on next pages!',
  NO_DATA_FOUND_PREVIOUS_PAGE: 'No data found on previous pages!',

  // comparison data updated successfully
  COMPARISON_FETCHING_FAILED: 'Failed to fetch comparison details.',
  COMPARISON_DATA_UPDATED: 'Comparison data updated successfully',
  COMPARISON_DATA_UPDATE_FAILED: 'Failed to update comparison data',
};

export default Toast;
