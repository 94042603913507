import { BoundingBoxType } from '../../services/constants/constants';
import { BoundingBoxActionTypes } from '../constants/boundingBoxActionTypes';

// State Structure for Draw Bounding Box feature
// States used -: activeBboxType, resizableBboxes, modifiedBboxes, ocrData
// Structure -:
//   resizableBboxes, modifiedBboxes -:
//   [
//     {
//       pageNumber: 1,
//       bboxes: [
//         {
//           id: "", // in case of draw new this will be UI generated else it will id from DB
//           type: "table | row | column | cell",
//           coordinates: [0,0,0,0],
//           gridlines: [
//             {
//               id: "",
//               type: "vertical | horizontal",
//               offset: 100, // if vertical then offset is from left coord else from top coord
//               order: 1, // starts from 1
//             }
//           ]
//         }
//       ]
//     }
//   ]

//   ocrData -:
// [
//   {
//     tableId: '',
//     rowCount: 2,
//     columnCount: 2,
//     isHeaderIncluded: true | false,
//     data: [
//       {
//         id: "", // always in the format '0_1' where 0 is the rowIndex and 1 is the columnIndex
//         text: "",
//         coordinates: [],
//         page_number: 1,
//       }
//     ]
//   }
// ]

// So in case of cellOcrData -> there will be 2 objects for 1 entity
// 1 for key and 1 for value to store their respective ocr text and coordinates
// cellOcrData -:
// [
//   {
//     id in case of add new cells -> this will be UI generated Row ID (equivalent entity id) and
//     in case of already existing cell, this will the cell ID present in DB
//     id: "",
//     type: "", this will be either key or value
//     text: "",
//     coordinates: [],
//     page_number: 1,
//   }
// ]

const tableCreatorInit = {
  tableId: '', rowCount: '1', columnCount: '', includeTableHeader: true, didAlreadyUse: false,
};

const cellInfoInit = {
  id: '',
  type: '',
};

const initialState = {
  activeDocumentIndex: 0,
  bbCoordinate: null,
  allBoundingBoxes: [],
  showAllBB: [],
  annotations: [],
  activeBboxType: BoundingBoxType.NONE,
  tableCreator: tableCreatorInit,
  // For cellInfo, id in case of add new cells -> this will be the row index i.e. 0,1,etc. In
  // in case of already existing cell, this will the cell ID present in DB
  cellInfo: cellInfoInit,
  resizableBboxes: [],
  modifiedBboxes: [],
  ocrData: [],
  cellOcrData: [],
};

const boundingBoxReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BoundingBoxActionTypes.SET_ACTIVE_DOCUMENT_INDEX:
      return { ...state, activeDocumentIndex: payload };

    case BoundingBoxActionTypes.SET_BBOX:
      return { ...state, bbCoordinate: payload };

    case BoundingBoxActionTypes.RESET_BBOX:
      return { ...state, bbCoordinate: null };

    case BoundingBoxActionTypes.SET_ALL_BBOX:
      return { ...state, allBoundingBoxes: payload };

    case BoundingBoxActionTypes.RESET_ALL_BBOX:
      return { ...state, allBoundingBoxes: [] };

    case BoundingBoxActionTypes.SET_SHOW_ALL_BBOX:
      return { ...state, showAllBB: payload };

    case BoundingBoxActionTypes.SET_ANNOTATIONS:
      return { ...state, annotations: payload };

    case BoundingBoxActionTypes.RESET_ANNOTATIONS:
      return { ...state, annotations: [] };

    case BoundingBoxActionTypes.SET_ACTIVE_BBOX_TYPE:
      return { ...state, activeBboxType: payload };

    case BoundingBoxActionTypes.SET_TABLE_CREATOR:
      return { ...state, tableCreator: payload };

    case BoundingBoxActionTypes.SET_CELL_INFO:
      return { ...state, cellInfo: payload };

    case BoundingBoxActionTypes.RESET_TABLE_CREATOR:
      return { ...state, tableCreator: tableCreatorInit };

    case BoundingBoxActionTypes.RESET_CELL_INFO:
      return { ...state, cellInfo: cellInfoInit };

    case BoundingBoxActionTypes.SET_RESIZABLE_BBOXES:
      return { ...state, resizableBboxes: payload };

    case BoundingBoxActionTypes.SET_MODIFIED_BBOXES:
      return { ...state, modifiedBboxes: payload };

    case BoundingBoxActionTypes.SET_OCR_DATA:
      return { ...state, ocrData: payload };

    case BoundingBoxActionTypes.SET_CELL_OCR_DATA:
      return { ...state, cellOcrData: payload };

    case BoundingBoxActionTypes.RESET_BOUNDING_BOX:
      return initialState;

    case BoundingBoxActionTypes.RESET_DRAW_BOUNDING_BOX:
      return {
        ...state,
        activeBboxType: BoundingBoxType.NONE,
        tableCreator: tableCreatorInit,
        cellInfo: cellInfoInit,
        resizableBboxes: [],
        modifiedBboxes: [],
        ocrData: [],
        cellOcrData: [],
      };

    default:
      return state;
  }
};

export default boundingBoxReducer;
