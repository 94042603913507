import { SubmissionActionTypes } from '../constants/submissionActionTypes';

const initialState = {
  submission: {},
  expandedRows: [],
  currentPage: 1,
  filterCurrentPage: 1,
  filterOptions: {
    timeframe: '',
    start_date: null,
    end_date: null,
    status: [],
    source: [],
    assignedTo: [],
  },
  filterOptionsFinal: {
    timeframe: '',
    start_date: null,
    end_date: null,
    status: [],
    source: [],
    assignedTo: [],
  },
  sortBy: 'ingestion_time',
  orderBy: 'desc',
  pageSize: '10',
  searchText: '',
  searchTextFinal: '',
  firstRender: true,
};

const submissionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SubmissionActionTypes.SET_SELECTED_SUBMISSION:
      return { ...state, submission: payload };

    case SubmissionActionTypes.REMOVE_SELECTED_SUBMISSION:
      return { ...state, submission: {} };

    case SubmissionActionTypes.SUBMISSIONS_ERROR:
      return { ...state, submissions: {} };

    case SubmissionActionTypes.SET_CURRENT_PAGE:
      return { ...state, currentPage: payload };

    case SubmissionActionTypes.SET_EXPANDED_ROWS:
      return { ...state, expandedRows: payload };

    case SubmissionActionTypes.SET_FILTER_CURRENT_PAGE:
      return { ...state, filterCurrentPage: payload };

    case SubmissionActionTypes.SET_FILTER_OPTIONS:
      return { ...state, filterOptions: payload };

    case SubmissionActionTypes.SET_FILTER_OPTIONS_FINAL:
      return { ...state, filterOptionsFinal: payload };

    case SubmissionActionTypes.SET_SORT_BY:
      return { ...state, sortBy: payload };

    case SubmissionActionTypes.SET_ORDER_BY:
      return { ...state, orderBy: payload };

    case SubmissionActionTypes.SET_PAGE_SIZE:
      return { ...state, pageSize: payload };

    case SubmissionActionTypes.SET_SEARCH_TEXT:
      return { ...state, searchText: payload };

    case SubmissionActionTypes.SET_SEARCH_TEXT_FINAL:
      return { ...state, searchTextFinal: payload };

    case SubmissionActionTypes.SET_FIRST_RENDER:
      return { ...state, firstRender: payload };

    case SubmissionActionTypes.RESET_SUBMISSION:
      return initialState;

    case SubmissionActionTypes.RESET_ALL_FILTER_OPTIONS:
      return { ...state, filterOptions: initialState.filterOptions };

    case SubmissionActionTypes.RESET_ALL_FILTER_OPTIONS_FINAL:
      return { ...state, filterOptionsFinal: initialState.filterOptionsFinal };

    default:
      return state;
  }
};

export default submissionsReducer;
