export const ExtractionActionTypes = {
  SET_SELECTED_EXTRACTION: 'SET_SELECTED_EXTRACTION',
  SET_EXCEL_TABLE_DATA: 'SET_EXCEL_TABLE_DATA',
  REMOVE_SELECTED_EXTRACTION: 'REMOVE_SELECTED_EXTRACTION',
  SET_SELECTED_DOCUMENT_ID: 'SET_SELECTED_DOCUMENT_ID',
  REMOVE_SELECTED_DOCUMENT_ID: 'REMOVE_SELECTED_DOCUMENT_ID',
  SET_EXTRACTION_CURRENT_PAGE: 'SET_EXTRACTION_CURRENT_PAGE',
  RESET_CURRENT_PAGE: 'RESET_CURRENT_PAGE',
  SET_IS_DATA_SKIPPED: 'SET_IS_DATA_SKIPPED',
  SET_ENTITY_COUNT: 'SET_ENTITY_COUNT',
  SET_TABLE_COUNT: 'SET_TABLE_COUNT',
  RESET_EXTRACTION: 'RESET_EXTRACTION',
  SET_DOCUMENT_SUMMARY: 'SET_DOCUMENT_SUMMARY',
  SET_SUMMARY_ENTITY_COUNT: 'SET_SUMMARY_ENTITY_COUNT',
  SET_SUMMARY_TABLE_COUNT: 'SET_SUMMARY_TABLE_COUNT',
  SET_ENTITY_DROPDOWN_SELECTED_VALUE: 'SET_ENTITY_DROPDOWN_SELECTED_VALUE',
  REMOVE_ENTITY_DROPDOWN_SELECTED_VALUE: 'REMOVE_ENTITY_DROPDOWN_SELECTED_VALUE',
};
