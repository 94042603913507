import React from 'react';
import firebase from 'firebase/compat/app';
import { FirebaseAppProvider } from 'reactfire';
import { node } from 'prop-types';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function FirebaseInit({ children }) {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      {children}
    </FirebaseAppProvider>
  );
}

FirebaseInit.propTypes = {
  children: node.isRequired,
};

export default FirebaseInit;
