// importing libraries
import React, { useState } from 'react';
import {
  string, func, arrayOf, bool,
} from 'prop-types';

// importing styles
import styles from './MultiSelectionDropdown.module.css';

// importing components
import IconButton from '../../molecules/icon-button/IconButton';
import { titlecase } from '../../../services/utils/Helper';
import CustomizedPopover from '../popover/Popover';
import Checkbox from '../Checkbox/Checkbox';
import ButtonOutlined from '../button/ButtonOutlined';

// bordertype consts
const borderSize = 'BORDER_SIZE';
const borderStyle = 'BORDER_STYLE';
const borderColor = 'BORDER_COLOR';
function MultiSelectionDropdown(props) {
  const {
    defaultClass, disabled, selectBG, cursor, selectHeight, selectWidth,
    selectBorder, activeSelectBorder, selectTextColor,
    optionsHeight,
    options, handleOptionClick, defaultOption, selectedOption, label,
    handleViewComparison, setIsOpen, optionsWidth, buttonText, isButtonDisabled,
    selectedMultipleDocumentIds,
  } = props;
  const [isOpenAnchor, setIsOpenAnchor] = useState(null);

  const handleClosePopover = () => {
    setIsOpenAnchor(null);
    setIsOpen(false);
  };

  const splitBorder = (border, borderType) => {
    const borderSplit = border.split(' ');
    if (borderType === borderSize) {
      return borderSplit[0];
    }
    if (borderType === borderStyle) {
      return borderSplit[1];
    }
    if (borderType === borderColor) {
      return borderSplit[2];
    }
    return border;
  };

  const handleClick = () => {
    if (handleViewComparison()) {
      handleClosePopover();
    }
  };

  return (
    <div
      className={styles.responsiveCustomSelect}
      style={{ width: selectWidth, height: selectHeight }}
    >
      {label && <label htmlFor="label">{label}</label>}
      <div
        onClick={(event) => {
          if (!disabled) {
            setIsOpenAnchor(event.currentTarget);
            setIsOpen(true);
          }
        }}
        className={`${styles.selectedOption} ${isOpenAnchor ? 'open' : ''}`}
        style={{
          backgroundColor: `var(--${selectBG})`,
          cursor,
          borderWidth: isOpenAnchor
            ? splitBorder(activeSelectBorder, borderSize)
            : splitBorder(selectBorder, borderSize),
          borderStyle: isOpenAnchor
            ? splitBorder(activeSelectBorder, borderStyle)
            : splitBorder(selectBorder, borderStyle),
          borderColor: isOpenAnchor
            ? `var(--${splitBorder(activeSelectBorder, borderColor)})`
            : `var(--${splitBorder(selectBorder, borderColor)})`,
        }}
        role="button"
        onKeyDown={() => { }}
        tabIndex={0}
      >
        <p
          className={`h5Regular ${styles.selectedValue} ${defaultClass}`}
          style={{ color: `var(--${selectTextColor})` }}
        >
          {titlecase(selectedOption || defaultOption)}
        </p>
        {isOpenAnchor ? (
          <div>
            <IconButton
              iconName="dropdownArrowUpIcon"
              iconHeight={5}
              iconWidth={8}
            />
          </div>
        ) : (
          <div>
            <IconButton
              iconName="dropdownArrowDownIcon"
              iconHeight={5}
              iconWidth={8}
            />
          </div>
        )}
      </div>

      <CustomizedPopover
        anchorEl={isOpenAnchor}
        open={Boolean(isOpenAnchor)}
        handleClose={handleClosePopover}
        anchorOriginHorizontal="left"
        transformOriginHorizontal="left"
        content={(
          <div style={{
            maxHeight: optionsHeight,
            width: optionsWidth,
          }}
          >
            <div
              className={`${styles.options} ${isOpenAnchor ? styles.fadeIn : ''}`}
              style={{
                maxHeight: `calc(${optionsHeight} - 2.375rem)`,
              }}
            >
              {options.map((option) => (
                <div
                  key={option.key}
                  onClick={() => handleOptionClick(option.key)}
                  className={`${styles.option} ${defaultClass} ${selectedMultipleDocumentIds.includes(option.key) && styles.selectedOption}`}
                  aria-hidden="true"
                >
                  <div className={styles.optionValue}>

                    <div>
                      <Checkbox
                        isChecked={selectedMultipleDocumentIds.includes(option.key)}
                      // handleChange={() => handleDocumentSelection(option.key)}
                      />
                    </div>
                    <div>
                      {option.value}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.displayBtn}>
              <ButtonOutlined
                text={buttonText}
                height="1.375rem"
                width="6.438rem"
                textFontSize="0.665rem"
                onClick={handleClick}
                disabled={isButtonDisabled}
              />
            </div>
          </div>
        )}
      />
    </div>

  );
}

MultiSelectionDropdown.propTypes = {
  options: arrayOf(Object).isRequired,
  handleOptionClick: func.isRequired,
  disabled: bool,
  defaultOption: string,
  selectedOption: string,
  label: string,
  selectBG: string,
  cursor: string,
  selectHeight: string,
  selectWidth: string,
  optionsHeight: string,
  selectBorder: string,
  activeSelectBorder: string,
  selectTextColor: string,
  defaultClass: string,
  handleViewComparison: func.isRequired,
  setIsOpen: func.isRequired,
  optionsWidth: string,
  buttonText: string,
  isButtonDisabled: bool,
  selectedMultipleDocumentIds: arrayOf(string).isRequired,
};

MultiSelectionDropdown.defaultProps = {
  defaultOption: 'Select an option',
  disabled: false,
  selectedOption: '',
  label: '',
  selectBG: 'white',
  cursor: 'pointer',
  selectHeight: '1.813rem',
  selectWidth: '12.625rem',
  optionsHeight: 'auto',
  selectBorder: '0.5px solid textFieldOutlineGray',
  activeSelectBorder: '0.5px solid blueDefault',
  selectTextColor: 'darkGray',
  defaultClass: 'h5Regular',
  optionsWidth: '22rem',
  buttonText: '',
  isButtonDisabled: false,
};

export default MultiSelectionDropdown;
