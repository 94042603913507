import { SubmissionActionTypes } from '../constants/submissionActionTypes';

export const setSelectedSubmission = (submission) => ({
  type: SubmissionActionTypes.SET_SELECTED_SUBMISSION,
  payload: submission,
});

export const removeSelectedSubmission = () => ({
  type: SubmissionActionTypes.REMOVE_SELECTED_SUBMISSION,
});

export const setCurrentPage = (pageNumber) => ({
  type: SubmissionActionTypes.SET_CURRENT_PAGE,
  payload: pageNumber,
});

export const setExpandedRows = (row) => ({
  type: SubmissionActionTypes.SET_EXPANDED_ROWS,
  payload: row,
});

export const setFilterCurrentPage = (pageNumber) => ({
  type: SubmissionActionTypes.SET_FILTER_CURRENT_PAGE,
  payload: pageNumber,
});

export const setFilterOptions = (filters) => ({
  type: SubmissionActionTypes.SET_FILTER_OPTIONS,
  payload: filters,
});

export const setFilterOptionsFinal = (finalFilters) => ({
  type: SubmissionActionTypes.SET_FILTER_OPTIONS_FINAL,
  payload: finalFilters,
});

export const setSortBy = (sortBy) => ({
  type: SubmissionActionTypes.SET_SORT_BY,
  payload: sortBy,
});

export const setOrderBy = (orderBy) => ({
  type: SubmissionActionTypes.SET_ORDER_BY,
  payload: orderBy,
});

export const setPageSize = (pageSize) => ({
  type: SubmissionActionTypes.SET_PAGE_SIZE,
  payload: pageSize,
});

export const setSearchText = (searchText) => ({
  type: SubmissionActionTypes.SET_SEARCH_TEXT,
  payload: searchText,
});

export const setSearchTextFinal = (searchTextFinal) => ({
  type: SubmissionActionTypes.SET_SEARCH_TEXT_FINAL,
  payload: searchTextFinal,
});

export const setFirstRender = (value) => ({
  type: SubmissionActionTypes.SET_FIRST_RENDER,
  payload: value,
});

export const resetSubmissionState = () => ({
  type: SubmissionActionTypes.RESET_SUBMISSION,
});

export const resetAllFilterOptions = () => ({
  type: SubmissionActionTypes.RESET_ALL_FILTER_OPTIONS,
});

export const resetAllFilterOptionsFinal = () => ({
  type: SubmissionActionTypes.RESET_ALL_FILTER_OPTIONS_FINAL,
});
