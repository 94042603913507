import React from 'react';
import './Loader.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Backdrop } from '@mui/material';
import { bool, string } from 'prop-types';

function Loader({ loading, size, sideDrawer }) {
  return (
    <div>
      {sideDrawer ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress
            thickness={3}
            color="success"
            size={size}
          />
        </Box>
      ) : (
        <div>
          <Backdrop
            open={loading}
            invisible // to hide the backdrop effect
            sx={{ color: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <CircularProgress
              thickness={3}
              color="success"
            />
          </Backdrop>
        </div>
      )}
    </div>
  );
}

Loader.propTypes = {
  loading: bool.isRequired,
  size: string,
  sideDrawer: bool,
};

Loader.defaultProps = {
  size: '',
  sideDrawer: false,
};

export default Loader;
