import React from 'react';
import PropTypes from 'prop-types';
import iconSprites from './icons.svg';
import style from './icon.module.css';

export function Icon({
  name, height, width, classes, color, display,
}) {
  const getClassName = () => {
    if (['filter', 'search'].includes(name)) {
      return style.darkGrayIcon;
    } if (name === 'sendEmail') {
      return style.infoBlueIcon;
    }
    return style.icon;
  };

  return (
    <span
      className={classes}
      style={{
        display, width: `${width}px`, height: `${height}px`,
      }}
    >
      <svg className={getClassName()} viewBox={`0 0 ${width} ${height}`} style={{ fill: `var(--${color})` }}>
        <use xlinkHref={`${iconSprites}#${name}`} />
      </svg>
    </span>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  classes: PropTypes.string,
  color: PropTypes.string,
  display: PropTypes.string,
};

Icon.defaultProps = {
  classes: '',
  color: 'darkBlue',
  display: 'inline-block',
};
