import { TimerActionTypes } from '../constants/timerActionTypes';

export const setReviewTime = (time) => ({
  type: TimerActionTypes.SET_REVIEW_TIME,
  payload: time,
});

export const setIsTimerPaused = (flag) => ({
  type: TimerActionTypes.SET_IS_TIMER_PAUSED,
  payload: flag,
});

export const setIsResetPopupOpen = (flag) => ({
  type: TimerActionTypes.SET_IS_RESET_POPUP_OPEN,
  payload: flag,
});

export const setIsTimeZero = (flag) => ({
  type: TimerActionTypes.SET_IS_TIME_ZERO,
  payload: flag,
});

export const resetTimerState = () => ({
  type: TimerActionTypes.RESET_TIMER,
});
