export const SubmissionActionTypes = {
  SET_SELECTED_SUBMISSION: 'SET_SELECTED_SUBMISSION',
  REMOVE_SELECTED_SUBMISSION: 'REMOVE_SELECTED_SUBMISSION',
  SUBMISSIONS_ERROR: 'SUBMISSIONS_ERROR',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_EXPANDED_ROWS: 'SET_EXPANDED_ROWS',
  SET_FILTER_CURRENT_PAGE: 'SET_FILTER_CURRENT_PAGE',
  SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
  SET_FILTER_OPTIONS_FINAL: 'SET_FILTER_OPTIONS_FINAL',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_ORDER_BY: 'SET_ORDER_BY',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_SEARCH_TEXT_FINAL: 'SET_SEARCH_TEXT_FINAL',
  SET_FIRST_RENDER: 'SET_FIRST_RENDER',
  RESET_SUBMISSION: 'RESET_SUBMISSION',
  RESET_ALL_FILTER_OPTIONS: 'RESET_ALL_FILTER_OPTIONS',
  RESET_ALL_FILTER_OPTIONS_FINAL: 'RESET_ALL_FILTER_OPTIONS_FINAL',
};
