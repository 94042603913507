import { useEffect, useState } from 'react';

const useTabChange = () => {
  const [isTabChanged, setIsTabChanged] = useState(undefined);

  useEffect(() => {
    const handleTabChange = () => {
      if (document.visibilityState === 'visible') {
        setIsTabChanged(false);
      } else {
        setIsTabChanged(true);
      }
    };

    window.addEventListener('visibilitychange', handleTabChange);

    return () => {
      window.removeEventListener('visibilitychange', handleTabChange);
    };
  }, []);

  return isTabChanged;
};

export default useTabChange;
