import { SystemActionTypes } from '../constants/systemActionTypes';

export const setSystemLoading = (loading) => ({
  type: SystemActionTypes.LOADING_SYSTEM_CALL,
  payload: loading,
});

export const setUsersList = (users) => ({
  type: SystemActionTypes.SET_USERS_LIST,
  payload: users,
});

export const setSourceList = (sources) => ({
  type: SystemActionTypes.SET_SOURCE_LIST,
  payload: sources,
});

export const setAppStatusList = (appStatuses) => ({
  type: SystemActionTypes.SET_APP_STATUS_LIST,
  payload: appStatuses,
});

export const setClassificationList = (classificationList) => ({
  type: SystemActionTypes.SET_CLASSIFICATION_LIST,
  payload: classificationList,
});

export const setSubClassificationList = (subClassificationList) => ({
  type: SystemActionTypes.SET_SUB_CLASSIFICATION_LIST,
  payload: subClassificationList,
});

export const setPermissions = (permissions) => ({
  type: SystemActionTypes.SET_PERMISSIONS,
  payload: permissions,
});

export const setLineOfBusinessList = (lineOfBusinessList) => ({
  type: SystemActionTypes.SET_LINE_OF_BUSINESS_LIST,
  payload: lineOfBusinessList,
});

export const setCarrierList = (carrierList) => ({
  type: SystemActionTypes.SET_CARRIER_LIST,
  payload: carrierList,
});

export const resetSystemState = () => ({
  type: SystemActionTypes.RESET_SYSTEM_CALL,
});
