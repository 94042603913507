export const ComparisonActionTypes = {
  SET_SELECTED_DOCUMENT_IDS: 'SET_SELECTED_DOCUMENT_IDS',
  REMOVE_SELECTED_DOCUMENT_IDS: 'REMOVE_SELECTED_DOCUMENT_IDS',
  SET_COMPARISON_SEARCH_TEXT: 'SET_COMPARISON_SEARCH_TEXT',
  CLEAR_COMPARISON_SEARCH_TEXT: 'CLEAR_COMPARISON_SEARCH_TEXT',
  SET_COMPARISON_DETAILS: 'SET_COMPARISON_DETAILS',
  REMOVE_COMPARISON_DETAILS: 'REMOVE_COMPARISON_DETAILS',
  RESET_COMPARISON: 'RESET_COMPARISON',
  SET_COMPARISON_TABLE_WIDTH: 'SET_COMPARISON_TABLE_WIDTH',
  REMOVE_COMPARISON_TABLE_WIDTH: 'REMOVE_COMPARISON_TABLE_WIDTH',
};
