import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import NavbarRoutes from '../../../../services/utils/NavbarRoutes';
import classes from './Sidebar.module.css';
import CustomizedTooltip from '../../../../components/atoms/Tooltip/CustomizedTooltip';
import useAxios from '../../../../hooks/useAxios';
import useLogout from '../../../../hooks/useLogout';
import Loader from '../../../../components/molecules/loader/Loader';
import IconButton from '../../../../components/molecules/icon-button/IconButton';
import { useUserAuth } from '../../../../context/UserAuthContext';
import CustomizedPopover from '../../../../components/atoms/popover/Popover';
import { DocumentService } from '../../../../services/constants/endpoints';
import usePermissions from '../../../../hooks/usePermissions';
import { useSnackbar } from '../../../../context/SnackbarContext';

function Sidebar() {
  const navBarItems = NavbarRoutes();
  const location = useLocation();
  const navigate = useNavigate();
  const axiosPrivate = useAxios();
  const [userDetailRef, setUserDetailsRef] = useState();
  const { logoutUser } = useLogout();
  const [loggingout, setLoggingout] = React.useState(false);
  const { user } = useUserAuth();
  const { checkPermission } = usePermissions();
  const { openSnackbar } = useSnackbar();

  const handleAvatarClick = (e) => {
    setUserDetailsRef(userDetailRef ? null : e.currentTarget);
  };

  const handleClose = () => {
    setUserDetailsRef(null);
  };

  const getDoc = (type) => {
    axiosPrivate
      .get(DocumentService.VIEW_DOCUMENT(type))
      .then((res) => {
        const createA = document.createElement('a');
        createA.setAttribute('href', res.data.data.url);
        createA.setAttribute('target', '_blank');
        createA.setAttribute('rel', 'noopener noreferrer');
        createA.click();
      })
      .catch(() => {
        openSnackbar('error', 'Loading document failed!');
      });
  };

  const handleLogout = async () => {
    try {
      setLoggingout(true);
      await logoutUser();
      navigate('/login', { state: { from: location } });
    } catch (error) {
      setLoggingout(false);
      navigate('/login', { state: { from: location } });
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <Loader loading={loggingout} />
      <Box className={classes.sidebar} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <div className={classes.sidebarMainNav}>
          {navBarItems.map((item) => {
            if (item.permissions.some((perm) => checkPermission(perm))
              || item.permissions?.length === 0) {
              return (
                <div className={location.pathname === item.href ? classes.sidebarActiveBtn : ''}>
                  <CustomizedTooltip text={item.tooltip} position="right" key={item.key}>
                    <IconButton
                      iconName={item.normalIcon}
                      iconWidth={item.width}
                      iconHeight={item.height}
                      iconClasses={classes.sidebarIconStyle}
                      iconColor="white"
                      handleClick={() => handleNavigation(item.href)}
                    />
                  </CustomizedTooltip>
                </div>
              );
            }

            return '';
          })}
        </div>
        <div className={classes.sidebarSecondaryNav}>
          <CustomizedTooltip text="User Guide" position="right">
            <IconButton
              iconName="guideInfo"
              iconWidth={16}
              iconHeight={17}
              iconClasses={classes.sidebarIconStyle}
              iconColor="white"
              handleClick={() => getDoc('user_guide')}
            />
          </CustomizedTooltip>
          <CustomizedTooltip text="FAQ" position="right">
            <IconButton
              iconName="faq"
              iconWidth={16}
              iconHeight={16}
              iconClasses={classes.sidebarIconStyle}
              iconColor="white"
              handleClick={() => getDoc('faq')}
            />
          </CustomizedTooltip>
          <CustomizedTooltip text="Notifications" position="right">
            <IconButton
              iconName="notificationUnread"
              iconWidth={17}
              iconHeight={20}
              iconClasses={classes.sidebarIconStyle}
              iconColor="white"
              handleClick={() => { }}
            />
          </CustomizedTooltip>
          <CustomizedTooltip text="Logout" position="right">
            <IconButton
              iconName="avatar"
              iconWidth={24}
              iconHeight={24}
              iconClasses={classes.sidebarIconStyle}
              iconColor="white"
              handleClick={handleAvatarClick}
              ref={userDetailRef}
            />
          </CustomizedTooltip>
          <CustomizedPopover
            anchorEl={userDetailRef}
            open={Boolean(userDetailRef)}
            handleClose={handleClose}
            anchorOriginVertical="center"
            anchorOriginHorizontal="right"
            transformOriginVertical="center"
            transformOriginHorizontal="left"
            content={(
              <div className={classes.popupUserDetail}>
                <span
                  className={`${classes.userDetailFont} ${classes.userStyle} h5Regular`}
                >
                  {user.email}
                </span>
                <span
                  onClick={handleLogout}
                  className={`${classes.userDetailFont} ${classes.logoutStyle} h5Regular`}
                  aria-hidden="true"
                >
                  Logout
                </span>
              </div>
            )}
          />
        </div>
      </Box>
    </>
  );
}

export default Sidebar;
