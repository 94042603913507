import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  bool, element, func, number, string,
} from 'prop-types';
import usePermissions from '../../../hooks/usePermissions';

const useStyles = makeStyles(() => ({
  buttonStyle: {
    color: 'var(--palewhite)',
    border: '1.5px solid var(--darkBlue)',
    borderRadius: '0.25rem',
    textTransform: 'none',
    height: '1.375rem',
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'var(--darkBlue)',
    '&:hover': {
      backgroundColor: 'var(--navyBlue)',
      border: '1.5px solid var(--navyBlue)',
    },
    '&:active': {
      backgroundColor: 'var(--darkBlue)',
    },
    '&:disabled': {
      backgroundColor: 'var(--textFieldOutlineGray)',
      border: '1.5px solid var(--textFieldOutlineGray)',
      cursor: 'not-allowed',
    },
  },
}));

function ButtonFilled(props) {
  const {
    defaultClass,
    text, onClick, disabled, icon, width, height, borderRadius,
    background, fontWeight, isTimerDependent,
  } = props;
  const classes = useStyles();
  const { shouldDisableHitl, shouldShowHitl } = usePermissions();

  const shouldDisable = () => {
    if (isTimerDependent) {
      return disabled || shouldDisableHitl();
    }
    return disabled;
  };

  const shouldShow = () => {
    if (isTimerDependent) {
      return shouldShowHitl();
    }
    return true;
  };

  return (
    shouldShow() ? (
      <div>
        <button
          type="button"
          className={`${classes.buttonStyle} ${defaultClass}`}
          onClick={onClick}
          disabled={shouldDisable()}
          style={{
            width, height, borderRadius: borderRadius || '0.25rem', background, fontWeight,
          }}
        >
          <span>{icon && <img src={icon} alt="" style={{ display: 'flex', marginRight: '0.5rem' }} />}</span>
          {text}
        </button>
      </div>
    ) : ('')
  );
}

ButtonFilled.propTypes = {
  defaultClass: string,
  text: string.isRequired,
  onClick: func.isRequired,
  disabled: bool,
  icon: element,
  width: string,
  height: string,
  borderRadius: string,
  background: string,
  fontWeight: number,
  isTimerDependent: bool,
};

ButtonFilled.defaultProps = {
  defaultClass: 'h6Regular',
  disabled: false,
  width: '',
  height: '',
  borderRadius: null,
  icon: null,
  background: 'var(--darkBlue)',
  fontWeight: 400,
  isTimerDependent: false,
};

export default ButtonFilled;
