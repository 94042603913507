import {
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { authInstance, provider, customProvider } from './firebase';

export function logInWithProvider() {
  return signInWithPopup(authInstance, provider);
}

export function logInWithCustomProvider() {
  return signInWithPopup(authInstance, customProvider);
}

export function logoutFirebase() {
  return signOut(authInstance);
}
