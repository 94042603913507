import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  bool, element, func, string,
} from 'prop-types';
import usePermissions from '../../../hooks/usePermissions';

const useStyles = makeStyles(() => ({
  buttonStyle: {
    color: 'var(--darkBlue)',
    borderRadius: '0.25rem',
    textTransform: 'none',
    height: '1.375rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'var(--darkBlue)',
    '&:hover': {
      border: '1px solid var(--navyBlue) !important',
      color: 'var(--navyBlue) !important',
    },
    '&:active': {
      border: '1px solid var(--darkBlue)',
      color: 'var(--darkBlue)',
    },
    '&:disabled': {
      color: 'var(--textFieldOutlineGray) !important',
      border: '1.5px solid var(--textFieldOutlineGray) !important',
      cursor: 'not-allowed',
    },
  },
  logo: {
    marginRight: '0.5rem',
  },
}));
function ButtonOutlined(props) {
  const {
    defaultClass,
    text, onClick, icon, borderColor, textColor, disabled, width, height, marginRight, imageMargin,
    isTimerDependent,
  } = props;
  const classes = useStyles();
  const { shouldDisableHitl, shouldShowHitl } = usePermissions();

  const shouldDisable = () => {
    if (isTimerDependent) {
      return disabled || shouldDisableHitl();
    }
    return disabled;
  };

  const shouldShow = () => {
    if (isTimerDependent) {
      return shouldShowHitl();
    }
    return true;
  };

  return (
    shouldShow() ? (
      <div>
        <button
          type="button"
          className={`${classes.buttonStyle} ${defaultClass}`}
          style={{
            border: `1px solid ${borderColor}`,
            width,
            height,
            marginRight,
            color: textColor,
          }}
          onClick={onClick}
          disabled={shouldDisable()}
        >
          {icon && <img className={`${classes.logo}`} src={icon} alt="logo" style={{ marginInline: imageMargin }} />}
          {text}
        </button>
      </div>
    ) : ('')
  );
}

ButtonOutlined.propTypes = {
  defaultClass: string,
  text: string.isRequired,
  onClick: func.isRequired,
  disabled: bool,
  icon: element,
  width: string,
  height: string,
  borderColor: string,
  textColor: string,
  marginRight: string,
  imageMargin: string,
  isTimerDependent: bool,
};

ButtonOutlined.defaultProps = {
  defaultClass: 'h6Regular',
  disabled: false,
  width: '',
  height: '',
  icon: null,
  borderColor: 'var(--darkBlue)',
  textColor: 'var(--darkBlue)',
  marginRight: '',
  imageMargin: '',
  isTimerDependent: false,
};

export default ButtonOutlined;
