// importing libraries
import React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import {
  string, func, bool, element,
  instanceOf,
} from 'prop-types';

// open: A boolean value decides weather we need to open the popup or not
// anchorEl: An HTML element. It's used to set the position of the popover.
// handleClose: Callback fired when the component requests to be closed.
// content: Html Element to render inside the Popover so that user can
// desgin the content as per the choice

// popover positioning
// anchor origin: This is the point on the anchor where the popover's anchorEl will attach to.
// anchorOriginVertical: top, center, bottom, x(px)
// anchorOriginHorizontal: left, center, right, x(px)

// transformOrigin: This is the point on the popover which will attach to the anchor's origin
// transformOriginHorizontal: left, center, right, x(px)
// transformOriginVertical: top, center, bottom, x(px)
function CustomizedPopover(props) {
  const {
    open, anchorEl, handleClose, content,
    anchorOriginVertical, anchorOriginHorizontal,
    transformOriginHorizontal, transformOriginVertical,
    margin,
  } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: anchorOriginHorizontal,
      }}
      transformOrigin={{
        vertical: transformOriginVertical,
        horizontal: transformOriginHorizontal,
      }}
      sx={{ margin }}
    >
      <Box>
        {content}
      </Box>
    </Popover>
  );
}

CustomizedPopover.propTypes = {
  open: bool.isRequired,
  anchorEl: instanceOf(Object),
  handleClose: func.isRequired,
  content: element.isRequired,
  anchorOriginVertical: string,
  anchorOriginHorizontal: string,
  transformOriginHorizontal: string,
  transformOriginVertical: string,
  margin: string,
};
CustomizedPopover.defaultProps = {
  anchorEl: null,
  anchorOriginVertical: 'bottom',
  anchorOriginHorizontal: 'right',
  transformOriginHorizontal: 'right',
  transformOriginVertical: 'top',
  margin: 'none',
};

export default CustomizedPopover;
