import React from 'react';
import './App.css';
import Routes from './routes/Routes';
import { SnackbarProvider } from './context/SnackbarContext';

function App() {
  return (
    <div className="App">
      <SnackbarProvider>
        <Routes />
      </SnackbarProvider>
    </div>
  );
}
export default App;
