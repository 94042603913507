import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useIdleTimeoutV2 from '../../../hooks/useIdleTimeoutV2';
import useLogout from '../../../hooks/useLogout';
import { useUserAuth } from '../../../context/UserAuthContext';
import { useSnackbar } from '../../../context/SnackbarContext';

const IDLE_TIME_LIMIT = process.env.REACT_APP_IDLE_TIMEOUT * 60; // in minutes

function IdleSessionTimeout() {
  const { isUserIdle } = useIdleTimeoutV2(IDLE_TIME_LIMIT * 60000);
  const navigate = useNavigate();
  const location = useLocation();
  const { logoutUser } = useLogout();
  const { user, updateTime } = useUserAuth();
  const { openSnackbar } = useSnackbar();

  const logout = async () => {
    try {
      const response = await logoutUser();
      if (response.data.status) {
        navigate('/login', { state: { from: location } });
      }
    } catch (error) {
      openSnackbar('error', error?.response?.data?.message);
    }
  };

  // logging out user if screen is idle for x time
  React.useEffect(() => {
    if (isUserIdle) {
      logout();
    }
  }, [isUserIdle]);

  // for logging out if last activity was x time back and tab is closed
  React.useEffect(() => {
    const newTime = Date.now();
    if (user.loggedInTime) {
      const difference = ((newTime - user.loggedInTime) / 60000);
      if (difference > IDLE_TIME_LIMIT) {
        logout();
      }
    }
  }, []);

  React.useEffect(() => {
    // setting the timer when tab is getting closed
    const setTimerBeforeUnload = (e) => {
      e.preventDefault();
      if (Object.values(user)?.every((value) => value !== '')) {
        updateTime(Date.now());
      }
    };

    window.addEventListener('beforeunload', setTimerBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', setTimerBeforeUnload);
    };
  }, []);

  return (
    <div style={{ display: 'none' }} />
  );
}

export default IdleSessionTimeout;
