import { SystemActionTypes } from '../constants/systemActionTypes';

const initialState = {
  users: [],
  sourceList: [],
  appStatusList: [],
  classificationList: [],
  subClassificationList: [],
  permissions: [],
  lineOfBusinessList: [],
  carrierList: [],
  loadingSystemCall: false,
};

const systemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SystemActionTypes.LOADING_SYSTEM_CALL:
      return { ...state, loadingSystemCall: payload };

    case SystemActionTypes.SET_USERS_LIST:
      return { ...state, users: payload };

    case SystemActionTypes.SET_SOURCE_LIST:
      return { ...state, sourceList: payload };

    case SystemActionTypes.SET_APP_STATUS_LIST:
      return { ...state, appStatusList: payload };

    case SystemActionTypes.SET_CLASSIFICATION_LIST:
      return { ...state, classificationList: payload };

    case SystemActionTypes.SET_SUB_CLASSIFICATION_LIST:
      return { ...state, subClassificationList: payload };

    case SystemActionTypes.SET_PERMISSIONS:
      return { ...state, permissions: payload };

    case SystemActionTypes.SET_LINE_OF_BUSINESS_LIST:
      return { ...state, lineOfBusinessList: payload };

    case SystemActionTypes.SET_CARRIER_LIST:
      return { ...state, carrierList: payload };

    case SystemActionTypes.RESET_SYSTEM_CALL:
      return initialState;

    default:
      return state;
  }
};

export default systemReducer;
