import { ExtractionActionTypes } from '../constants/extractionActionTypes';

export const setExtractionDetails = (extraction) => ({
  type: ExtractionActionTypes.SET_SELECTED_EXTRACTION,
  payload: extraction,
});

export const setExcelTableDetails = (tableData) => ({
  type: ExtractionActionTypes.SET_EXCEL_TABLE_DATA,
  payload: tableData,
});

export const removeExtractionDetails = () => ({
  type: ExtractionActionTypes.REMOVE_SELECTED_EXTRACTION,
});

export const setSelectedDocumentId = (id) => ({
  type: ExtractionActionTypes.SET_SELECTED_DOCUMENT_ID,
  payload: id,
});

export const removeSelectedDocumentId = () => ({
  type: ExtractionActionTypes.REMOVE_SELECTED_DOCUMENT_ID,
});

export const setCurrentPage = (pageNumber) => ({
  type: ExtractionActionTypes.SET_EXTRACTION_CURRENT_PAGE,
  payload: pageNumber,
});

export const resetCurrentPage = () => ({
  type: ExtractionActionTypes.RESET_CURRENT_PAGE,
});

export const setIsDataSkipped = (value) => ({
  type: ExtractionActionTypes.SET_IS_DATA_SKIPPED,
  payload: value,
});

export const setEntityCount = (value) => ({
  type: ExtractionActionTypes.SET_ENTITY_COUNT,
  payload: value,
});

export const setTableCount = (value) => ({
  type: ExtractionActionTypes.SET_TABLE_COUNT,
  payload: value,
});

export const resetExtractionState = () => ({
  type: ExtractionActionTypes.RESET_EXTRACTION,
});

export const setSummaryDetails = (summary) => ({
  type: ExtractionActionTypes.SET_DOCUMENT_SUMMARY,
  payload: summary,
});

export const setSummaryEntityCount = (value) => ({
  type: ExtractionActionTypes.SET_SUMMARY_ENTITY_COUNT,
  payload: value,
});

export const setSummaryTableCount = (value) => ({
  type: ExtractionActionTypes.SET_SUMMARY_TABLE_COUNT,
  payload: value,
});

export const setEntityDropdownSelectedValue = (value) => ({
  type: ExtractionActionTypes.SET_ENTITY_DROPDOWN_SELECTED_VALUE,
  payload: value,
});

export const removeEntityDropdownSelectedValue = (value) => ({
  type: ExtractionActionTypes.REMOVE_ENTITY_DROPDOWN_SELECTED_VALUE,
  payload: value,
});
