import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { axiosPrivate } from '../config/axios';
import { useUserAuth } from '../context/UserAuthContext';
import { AuthService } from '../services/constants/endpoints';
import { useSnackbar } from '../context/SnackbarContext';

const useAxios = () => {
  const { user, updateUser, removeUser } = useUserAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { openSnackbar } = useSnackbar();

  const logout = () => {
    removeUser();
    navigate('/login', { state: { from: location } });
  };

  const refreshToken = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.refreshToken}`,
      },
    };

    const response = await axiosPrivate.post(`${AuthService.REFRESH_TOKEN}/${user.recordId}`, {}, config);
    const responseData = response.data.data;
    const idToken = responseData.id_token;
    const refreshTokenFinal = responseData.refresh_token;
    const recordId = responseData.record_id;

    return [idToken, refreshTokenFinal, recordId];
  };

  useEffect(() => {
    if (!user.token && !location.pathname.includes('login')) {
      logout();
      return undefined;
    }
    const requestIntercept = axiosPrivate.interceptors.request.use(async (config) => {
      const newConfig = { ...config };
      if (!newConfig.headers.Authorization && user.token) {
        newConfig.headers.Authorization = `Bearer ${user.token}`;
      }
      return newConfig;
    }, (error) => Promise.reject(error));

    let refreshingFunc;

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const previousRequest = error?.config;
        if (error?.response?.status === 401 && !previousRequest._retry) {
          previousRequest._retry = true;

          try {
            if (!refreshingFunc) refreshingFunc = refreshToken();
            const [idToken, refreshTokenFinal, recordId] = await refreshingFunc;
            if (idToken && refreshTokenFinal && recordId) {
              updateUser(user.email, idToken, refreshTokenFinal, recordId, Date.now());

              previousRequest.headers.Authorization = `Bearer ${idToken}`;

              return axiosPrivate(previousRequest);
            }
            logout();
            return Promise.reject(error);
          } catch (err) {
            logout();
            return Promise.reject(err);
          } finally {
            refreshingFunc = undefined;
          }
        }

        if (error?.response?.status === 403) {
          openSnackbar('error', error.response?.data?.message);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  });

  return axiosPrivate;
};

export default useAxios;
