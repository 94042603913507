// importing libraries
import React from 'react';
import { Tab, Tabs } from '@mui/material';
import {
  number, func, string, arrayOf, shape,
  bool,
} from 'prop-types';

// importing components
import CustomizedTooltip from '../../atoms/Tooltip/CustomizedTooltip';

// importing styles
import classes from './CustomTabs.module.css';

function CustomTabs(props) {
  const {
    value, handleChange, tabsOptions, minWidth,
    isChip, tabHeight, paddingTop, paddingBottom, paddingLeft,
    paddingRight, marginTop, alignItems, textAlign,
  } = props;
  return (
    <div className={classes.tabsContainer}>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { backgroundColor: 'var(--afterWorkBlue)' },
        }}
        sx={{
          '.Mui-selected': {
            color: 'var(--navyBlue) !important',
          },
          minHeight: '2rem',
          height: tabHeight,
          marginTop,
          width: '100%',
          '.MuiTabs-flexContainer': {
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
          },
        }}
      >
        {
          tabsOptions && tabsOptions.map((tab) => (
            <Tab
              sx={{
                textTransform: 'none',
                minHeight: 0,
                paddingTop,
                paddingBottom,
                paddingLeft,
                paddingRight,
                minWidth,
                alignItems,
                fontSize: '0.906rem',
                opacity: 1,
                maxWidth: minWidth,
              }}
              label={(
                <div style={{ width: '100%' }}>
                  <CustomizedTooltip text={tab.label} position="top">
                    <div style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign,
                    }}
                    >
                      {tab.label}
                    </div>
                    {
                      isChip
                      && (
                        <div className={classes.tabChip}>
                          <span className={`h6Regular ${classes.tabChipText}`}>
                            {tab.chip}
                          </span>
                        </div>
                      )
                    }
                  </CustomizedTooltip>
                </div>
              )}
              value={tab.value}
              index={tab.index}
            />
          ))
        }
      </Tabs>
    </div>
  );
}

CustomTabs.propTypes = {
  value: number.isRequired,
  handleChange: func.isRequired,
  tabsOptions: arrayOf(shape({
    label: string.isRequired,
    value: number.isRequired,
    index: number.isRequired,
  })).isRequired,
  minWidth: string,
  isChip: bool,
  tabHeight: string,
  paddingTop: string,
  paddingBottom: string,
  paddingLeft: string,
  paddingRight: string,
  marginTop: string,
  alignItems: string,
  textAlign: string,
};

CustomTabs.defaultProps = {
  minWidth: '25%',
  isChip: false,
  tabHeight: '2rem',
  paddingTop: '0.375rem',
  paddingBottom: '0.375rem',
  paddingLeft: '1rem',
  paddingRight: '1rem',
  marginTop: '0',
  alignItems: 'center',
  textAlign: 'center',
};

export default CustomTabs;
