import { ComparisonActionTypes } from '../constants/comparisonActionTypes';

const initialState = {
  selectedDocumentIds: [],
  searchText: '',
  comparison: null,
  comparisonTableWidth: 50,
};

const comparisonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ComparisonActionTypes.SET_SELECTED_DOCUMENT_IDS:
      return { ...state, selectedDocumentIds: payload };

    case ComparisonActionTypes.REMOVE_SELECTED_DOCUMENT_IDS:
      return { ...state, selectedDocumentIds: [] };

    case ComparisonActionTypes.SET_COMPARISON_SEARCH_TEXT:
      return { ...state, searchText: payload };

    case ComparisonActionTypes.CLEAR_COMPARISON_SEARCH_TEXT:
      return { ...state, searchText: '' };

    case ComparisonActionTypes.SET_COMPARISON_DETAILS:
      return { ...state, comparison: payload };

    case ComparisonActionTypes.REMOVE_COMPARISON_DETAILS:
      return { ...state, comparison: null };

    case ComparisonActionTypes.RESET_COMPARISON:
      return initialState;

    case ComparisonActionTypes.SET_COMPARISON_TABLE_WIDTH:
      return { ...state, comparisonTableWidth: payload };

    case ComparisonActionTypes.REMOVE_COMPARISON_TABLE_WIDTH:
      return { ...state, comparisonTableWidth: 50 };

    default:
      return state;
  }
};

export default comparisonReducer;
