import React, {
  createContext, useContext, useEffect, useMemo,
  useRef,
} from 'react';
import { node } from 'prop-types';
import * as firebaseWrapper from '../services/utils/firebaseWrapper';
import useLocalStorage from '../hooks/useLocalStorage';

const userAuthContext = createContext();
const userInit = {
  email: '',
  token: '',
  refreshToken: '',
  recordId: '',
  loggedInTime: '',
};

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useLocalStorage('user', userInit);
  const userRef = useRef(user);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  const updateUser = (email, token, refreshToken, recordId, time) => {
    const updatedUser = {
      email,
      token,
      refreshToken,
      recordId,
      loggedInTime: time,
    };
    setUser(updatedUser);
  };

  const updateTime = (time) => {
    const updatedUser = { ...userRef.current, loggedInTime: time };
    setUser(updatedUser);
  };

  const removeUser = () => {
    setUser(userInit);
  };

  const logInWithProvider = () => {
    if (process.env.REACT_APP_AUTH_PROVIDER === 'FIREBASE') {
      return firebaseWrapper.logInWithProvider();
    }

    return '';
  };

  const logInWithCustomProvider = () => {
    if (process.env.REACT_APP_AUTH_PROVIDER === 'FIREBASE') {
      return firebaseWrapper.logInWithCustomProvider();
    }

    return '';
  };

  const logOut = () => firebaseWrapper.logoutFirebase();

  const authValue = useMemo(() => ({
    user, updateUser, removeUser, logInWithProvider, logInWithCustomProvider, logOut, updateTime,
  }), [user]);

  return (
    <userAuthContext.Provider value={authValue}>
      {children}
    </userAuthContext.Provider>
  );
}

UserAuthContextProvider.propTypes = {
  children: node.isRequired,
};

export function useUserAuth() {
  return useContext(userAuthContext);
}
