import React, {
  createContext, useContext, useMemo, useRef, useState,
} from 'react';
import { node } from 'prop-types';
import Snackbar from '../components/atoms/Snackbar/Snackbar';
import classes from '../components/atoms/Snackbar/Snackbar.module.css';
import Toast from '../services/constants/toasters';

const SnackbarContext = createContext();

export function SnackbarProvider({ children }) {
  const [snackbars, setSnackbars] = useState([]);
  // Value of position should be - top-left, top-right, bottom-left and bottom-right
  const [position] = useState('top-right');
  const idCounter = useRef(1); // Initialize a counter ref

  const openSnackbar = (type, message) => {
    const newSnackbar = {
      id: idCounter.current, type,
    };
    idCounter.current += 1;

    if (message && typeof message === 'string' && message !== '') {
      newSnackbar.message = message;
    } else if (type === 'success') {
      newSnackbar.message = Toast.DEFAULT_SUCCESS_TOAST_MSG;
    } else {
      newSnackbar.message = Toast.DEFAULT_ERROR_TOAST_MSG;
    }
    setSnackbars((prevSnackbars) => [...prevSnackbars, newSnackbar]);

    // Automatically close the snackbar after a delay of 5 seconds
    setTimeout(() => {
      setSnackbars(
        (prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.id !== newSnackbar.id),
      );
    }, 5000);
  };

  const closeSnackbar = (id) => {
    setSnackbars((prevSnackbars) => prevSnackbars.filter((snackbar) => snackbar.id !== id));
  };

  const memoizedValue = useMemo(() => ({ openSnackbar, closeSnackbar }), [
    openSnackbar,
    closeSnackbar,
  ]);

  const getPositionStyles = () => {
    switch (position) {
      case 'top-left':
        return { top: '1.25rem', left: '1.25rem' };
      case 'bottom-right':
        return { bottom: '1.25rem', right: '1.25rem' };
      case 'bottom-left':
        return { bottom: '1.25rem', left: '1.25rem' };
      case 'top-right':
      default:
        return { top: '1.25rem', right: '1.25rem' };
    }
  };

  return (
    <SnackbarContext.Provider value={memoizedValue}>
      {children}
      <div className={classes.snackbarContainer} style={getPositionStyles()}>
        {snackbars.map((snackbar) => (
          <Snackbar
            key={`${snackbar.id}-${snackbar.message}`}
            handleClose={() => closeSnackbar(snackbar.id)}
            type={snackbar.type}
            message={snackbar.message}
          />
        ))}
      </div>
    </SnackbarContext.Provider>
  );
}

SnackbarProvider.propTypes = {
  children: node.isRequired,
};

export const useSnackbar = () => useContext(SnackbarContext);
