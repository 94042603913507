// importing libraries
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
// importing components
import useAxios from './useAxios';
import { useSnackbar } from '../context/SnackbarContext';
import {
  removeExtractionDetails,
  setExtractionDetails,
  setSelectedDocumentId,
  removeSelectedDocumentId,
  resetCurrentPage,
  setCurrentPage,
  setIsDataSkipped,
  setEntityCount,
  setTableCount,
  setSummaryDetails,
  setSummaryEntityCount,
  setSummaryTableCount,
  setExcelTableDetails,
  setEntityDropdownSelectedValue,
  removeEntityDropdownSelectedValue,
} from '../redux/actions/extractionActions';
import { ExtractionService } from '../services/constants/endpoints';
import { axiosCancelToken } from '../config/axios';
import {
  resetAllBbox,
  setAllBbox,
  setShowAllBbox,
} from '../redux/actions/boundingBoxActions';
import Toast from '../services/constants/toasters';
import { getFileExtension, isExcel } from '../services/utils/Helper';
import { TOTAL_ROWS_TO_DISPLAY } from '../services/constants/constants';

function useExtraction(applicationId, docId) {
  const axiosPrivate = useAxios();
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const excelTable = useSelector((state) => state.extraction.excelTable);
  const [loading, setLoading] = useState(false);

  const fetchTableData = async (extHdrId, sectionId, tableId, pageNumber) => {
    // Return early if no tableId is provided
    if (!extHdrId || !sectionId || !tableId) return null;
    try {
      // Construct the API endpoint using the provided parameters
      const endpoint = ExtractionService.FETCH_TABLE(
        applicationId,
        docId,
        extHdrId,
        sectionId,
        tableId,
        pageNumber,
        TOTAL_ROWS_TO_DISPLAY,
      );
      // Make the API call to fetch table data
      const response = await axiosPrivate.get(endpoint);

      // Return the data from the API response
      return response.data;
    } catch (error) {
      // Extract the error message from the response, or use a default message
      const errorMessage = error?.response?.data?.message || 'An error occurred';

      // Display the error message in a snackbar
      openSnackbar('error', errorMessage);

      // Return null in case of an error
      return null;
    }
  };

  const getExcelTableData = async (pageDetails, pageNumber) => {
    // Check if pageDetails is empty or undefined
    if (!pageDetails || !pageDetails.length) {
      openSnackbar('error', 'Table details are missing or empty');
      return;
    }

    // Map over each page in pageDetails to create an array of promises
    const allTablePromises = pageDetails.map(async (page, pageIndex) => {
      // Extract sections from the current page, or use an empty array if sections are not available
      const sections = page?.tables?.sections || [];

      // If no sections are found, show an error and return an empty result for this page
      if (sections.length === 0) {
        openSnackbar('error', 'No section found');
        return { pageIndex, results: [] };
      }

      // Collect promises for all API calls within this page's sections and values
      const sectionPromises = sections.flatMap(
        (section) => section.values.map(
          // Each promise fetches data for a specific value in a section
          (value) => fetchTableData(page.ext_header_id, section.id, value.id, pageNumber),
        ),
      );

      try {
        // Wait for all API calls for this page to complete in parallel
        const resultsForPage = await Promise.all(sectionPromises);

        // Return the results along with the pageIndex to maintain order
        return { pageIndex, results: resultsForPage };
      } catch (error) {
        // setting loading to false as something went wrong
        setLoading(false);
        // Handle any errors during the API calls and show an error message
        openSnackbar('error', error?.response?.data?.message || 'Error fetching data');
        return { pageIndex, results: [] };
      }
    });

    try {
      setLoading(true);
      // Wait for all page-level promises to resolve
      const allTableResults = await Promise.all(allTablePromises);

      // Dispatch the results for the first page (or an empty array if no results) to the state
      dispatch(setExcelTableDetails(allTableResults[0]?.results || []));
    } catch (err) {
      openSnackbar('error', 'Failed to fetch table data');
      setLoading(false);
    }
  };

  const fetchDocumentExtraction = async (
    pageNumber = 1,
    skipToData = false,
    skipToNextData = false,
    config = null,
    isLoading = false,
  ) => {
    const payload = {
      page_number: pageNumber,
      row_offset: 0,
      row_limit: 0,
      skip_to_data: skipToData,
      fetch_next_page: skipToNextData,
    };
    setLoading(true);
    return axiosPrivate
      .post(
        `${ExtractionService.VIEW_EXTRACTION(applicationId, docId)}`,
        payload,
        config,
      )
      .then((response) => {
        if (response?.status === 204) {
          openSnackbar(
            'info',
            skipToNextData
              ? Toast.NO_DATA_FOUND_NEXT_PAGE
              : Toast.NO_DATA_FOUND_PREVIOUS_PAGE,
          );
        } else {
          const extractionCp = { ...response.data.data };
          dispatch(setExtractionDetails(extractionCp));
          const extension = getFileExtension(response.data.data.document_name);
          if (isExcel(extension)) {
            getExcelTableData(response.data.data.output_json.page_details, pageNumber);
          }

          if (skipToData) {
            dispatch(
              setCurrentPage(
                extractionCp.output_json.page_details[0].page_number,
              ),
            );
          }
          if (!isExcel(extension)) {
            // setLoading(false);
          }
        }
        if (isLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err?.message !== 'canceled') {
          setLoading(false);
          openSnackbar('error', err?.response?.data?.message);
        }
      });
  };

  // setLoading(true);
  const fetchDocumentSummary = async (config = null) => (
    axiosPrivate
      .get(`${ExtractionService.SUMMARY(applicationId, docId)}`, config)
      .then((response) => {
        for (let index = 0; index < response.data.data.key_values?.sections.length; index += 1) {
          const data = response.data.data.key_values.sections[index];
          data.id = uuidv4();
          response.data.data.key_values.sections[index] = data;
          for (let valIndex = 0; valIndex < data.values.length; valIndex += 1) {
            const element = data.values[valIndex];
            element.indexId = uuidv4();
            data.values[valIndex] = element;
          }
        }
        dispatch(setSummaryDetails(response.data.data));
      })
      .catch((err) => {
        if (err?.message !== 'canceled') {
          setLoading(false);
          openSnackbar('error', err?.response?.data?.message);
        }
      })
  );

  const fetchExtractionDetails = (currentPage) => {
    setLoading(true);
    Promise.all([fetchDocumentExtraction(currentPage), fetchDocumentSummary()])
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        openSnackbar('error', 'Something went wrong');
      });
  };

  const addKeyValue = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .post(
        `${ExtractionService.ADD_KEY_VALUES(applicationId, docId)}`,
        payload,
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const addTableRows = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .post(
        `${ExtractionService.ADD_ROWS(applicationId, docId, tableId)}`,
        payload,
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const addPaginatedTableRows = async (tableId, payload, currentPage = 1, extHdrId, sectionId) => {
    try {
      // Send a POST request to add new rows to the table
      await axiosPrivate.post(
        ExtractionService.ADD_ROWS(applicationId, docId, tableId),
        payload,
      );

      // Fetch the updated table data after adding the new rows
      const updatedTable = await fetchTableData(extHdrId, sectionId, tableId, currentPage);

      // Update the existing table data with the new data
      const updatedTableData = excelTable
        .map((table) => (table.data.table_id === tableId ? updatedTable : table));

      // Dispatch the updated table data to the Redux store
      dispatch(setExcelTableDetails(updatedTableData));

      return true;
    } catch (err) {
      // Display an error message in case of a failure
      openSnackbar('error', err?.response?.data?.message || 'An error occurred');
      return false;
    }
  };

  const editEntities = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .put(
        `${ExtractionService.EDIT_KEY_VALUES(applicationId, docId)}`,
        payload,
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const editTableRows = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .put(
        `${ExtractionService.EDIT_ROWS(applicationId, docId, tableId)}`,
        payload,
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const editPaginatedTableRows = async (
    tableId, // ID of the table being edited
    payload, // Data payload to send with the PUT request
    currentPage = 1, // Current page number, default is 1
    extHeaderId, // External header ID related to the table
    sectionId, // Section ID related to the table
  ) => {
    try {
      // Send the PUT request to update the table rows on the server
      await axiosPrivate.put(
        `${ExtractionService.EDIT_ROWS(applicationId, docId, tableId)}`,
        payload,
      );

      // Fetch the updated table data for the current page after the update
      const res = await fetchTableData(extHeaderId, sectionId, tableId, currentPage);

      // Create a deep copy of the current excelTable data and
      // update the specific table with the new data
      const tableCopy = excelTable
        .map((table) => (
          // Replace the matching table entry with the new data
          table.data.table_id === tableId ? res : table));

      // Dispatch the updated table data to the store
      dispatch(setExcelTableDetails(tableCopy));

      return true; // Return true to indicate that the operation was successful
    } catch (err) {
      // Handle any errors that occur during the API call or data processing
      // Display an error message in a snackbar with the specific error message if available
      openSnackbar('error', err?.response?.data?.message || 'An error occurred');

      return false; // Return false to indicate that the operation failed
    }
  };

  const deleteEntities = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .delete(`${ExtractionService.DELETE_KEY_VALUES(applicationId, docId)}`, {
        data: payload,
      })
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deleteTableRows = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .delete(
        `${ExtractionService.DELETE_ROWS(applicationId, docId, tableId)}`,
        { data: payload },
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deletePaginatedTableRows = async (
    tableId, // ID of the table being edited
    payload, // Data payload to send with the DELETE request
    currentPage = 1, // Current page number, default is 1
    extHeaderId, // External header ID related to the table
    sectionId, // Section ID related to the table
  ) => {
    try {
      // Send a DELETE request to remove rows from the table
      await axiosPrivate.delete(
        `${ExtractionService.DELETE_ROWS(applicationId, docId, tableId)}`,
        { data: payload },
      );

      // Fetch the updated table data for the current page after the deletion
      const updatedTableData = await fetchTableData(extHeaderId, sectionId, tableId, currentPage);

      // Create a new array with the updated table data
      const updatedExcelTable = excelTable
        .map((table) => (
          table.data.table_id === tableId
            ? updatedTableData
            : table)); // Replace the matching table entry

      // Dispatch the updated table data to the store
      dispatch(setExcelTableDetails(updatedExcelTable));

      return true; // Return true to indicate that the operation was successful
    } catch (err) {
      // Handle any errors that occur during the API call or data processing
      openSnackbar('error', err?.response?.data?.message || 'An error occurred'); // Display an error message
      return false; // Return false to indicate that the operation failed
    }
  };

  const fetchAllBoundingBoxes = () => {
    const source = axiosCancelToken.source();
    const config = { cancelToken: source.token };

    axiosPrivate
      .get(ExtractionService.GET_ALL_BBOXES(applicationId, docId), config)
      .then((response) => {
        const allCoordinates = [];

        for (
          let pageIndex = 0;
          pageIndex < response.data.data.length;
          pageIndex += 1
        ) {
          const pageBboxes = response.data.data[pageIndex];

          for (
            let bboxIndex = 0;
            bboxIndex < pageBboxes.bbox.length;
            bboxIndex += 1
          ) {
            const bbox = pageBboxes.bbox[bboxIndex];

            allCoordinates.push({
              id: uuidv4(),
              doc_index: 0,
              page_number: +pageBboxes.page_number,
              coordinates: bbox,
            });
          }
        }

        dispatch(setAllBbox(allCoordinates));
      })
      .catch((err) => {
        openSnackbar('error', err?.response?.data?.message);
      })
      .finally(() => source.cancel());
  };

  const removeAllBoundingBoxes = () => {
    dispatch(resetAllBbox());
    dispatch(setShowAllBbox([]));
  };

  const removeDocumentExtraction = () => {
    dispatch(removeExtractionDetails());
  };

  const storeSelectedDocumentId = (documentId) => {
    dispatch(setSelectedDocumentId(documentId));
  };

  const resetSelectedDocumentId = () => {
    dispatch(removeSelectedDocumentId());
  };

  const storeCurrentPageNumber = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
  };

  const resetCurrentPageNumber = () => {
    dispatch(resetCurrentPage());
  };

  const resetIsDataSkipped = () => {
    dispatch(setIsDataSkipped(false));
  };

  const editTableColumn = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .put(
        `${ExtractionService.TABLE_COLUMNS(applicationId, docId, tableId)}`,
        payload,
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deleteTableColumn = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .delete(
        `${ExtractionService.TABLE_COLUMNS(applicationId, docId, tableId)}`,
        { data: payload },
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deletePaginatedTableColumn = async (
    tableId, // ID of the table being edited
    payload, // Data payload to send with the DELETE request
    currentPage = 1, // Current page number, default is 1
    extHeaderId, // External header ID related to the table
    sectionId, // Section ID related to the table
  ) => {
    try {
      // Send a DELETE request to remove column(s) from the table
      await axiosPrivate.delete(
        `${ExtractionService.TABLE_COLUMNS(applicationId, docId, tableId)}`,
        { data: payload },
      );

      // Fetch the updated table data for the current page after the deletion
      const updatedTableData = await fetchTableData(extHeaderId, sectionId, tableId, currentPage);
      // Create a new array with the updated table data
      const updatedExcelTable = excelTable
        .map((table) => (
          table.data.table_id === tableId
            ? updatedTableData
            : table)); // Replace the matching table entry
      // Dispatch the updated table data to the store
      dispatch(setExcelTableDetails(updatedExcelTable));

      return true; // Return true to indicate that the operation was successful
    } catch (err) {
      // Handle any errors that occur during the API call or data processing
      openSnackbar('error', err?.response?.data?.message || 'An error occurred'); // Display an error message
      return false; // Return false to indicate that the operation failed
    }
  };

  const addTableColumn = async (
    tableId,
    payload,
    currentPage = 1,
    addTable = false,
  ) => {
    setLoading(true);
    return axiosPrivate
      .post(
        `${ExtractionService.TABLE_COLUMNS(applicationId, docId, tableId)}`,
        payload,
      )
      .then(() => {
        // don't call fetchDocumentExtraction when adding a table
        if (!addTable) {
          fetchExtractionDetails(currentPage);
        } else {
          setLoading(false);
        }
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const addPaginatedTableColumn = async (
    tableId,
    payload,
    currentPage = 1,
    extHeaderId,
    sectionId,
  ) => {
    try {
      // Send a POST request to add columns to the table
      await axiosPrivate.post(
        ExtractionService.TABLE_COLUMNS(applicationId, docId, tableId),
        payload,
      );

      // Fetch the updated table data after adding the columns
      const updatedTableData = await fetchTableData(
        extHeaderId,
        sectionId,
        tableId,
        currentPage,
      );

      // Update the excelTable array by replacing the matching table with the updated data
      const updatedExcelTable = excelTable
        .map((table) => (table.data.table_id === tableId ? updatedTableData : table));

      // Dispatch the updated table data to the Redux store
      dispatch(setExcelTableDetails(updatedExcelTable));

      // Indicate successful operation
      return true;
    } catch (err) {
      // Display an error message if something goes wrong
      openSnackbar('error', err?.response?.data?.message || 'An error occurred');
      return false;
    }
  };

  const addTable = async (payload) => {
    setLoading(true);
    return axiosPrivate
      .post(`${ExtractionService.ADD_TABLE(applicationId, docId)}`, payload)
      .then((response) => response.data.data.table_id)
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deleteTableCall = async (tableIds, currentPage = 1) => {
    setLoading(true);
    const errs = [];

    const promises = tableIds.map((tableID) => axiosPrivate
      .delete(
        `${ExtractionService.DELETE_TABLE(applicationId, docId, tableID)}`,
      )
      .then((response) => response.data.status)
      .catch((err) => {
        errs.push(err?.response?.data?.message);
        return false;
      }));

    return Promise.all(promises)
      .then((results) => {
        fetchExtractionDetails(currentPage);
        if (errs.length > 0) {
          openSnackbar('error', errs[0]);
          return false;
        }
        return results.every((result) => result === true);
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false;
      });
  };

  const storeEntityCount = (entityCount) => {
    dispatch(setEntityCount(entityCount));
  };

  const storeTableCount = (tableCount) => {
    dispatch(setTableCount(tableCount));
  };

  const exportDocument = (format, actionType) => {
    setLoading(true);
    let fileName = 'extraction';
    let api = `${ExtractionService.EXPORT_DOCUMENT(
      applicationId,
      docId,
      format,
    )}`;
    if (actionType === 'summary') {
      api = `${ExtractionService.EXPORT_SUMMARY(applicationId, docId, format)}`;
      fileName = 'summary';
    }
    axiosPrivate
      .get(api)
      .then((response) => {
        const downloadUrl = response.data.data.url;
        return axiosPrivate.get(downloadUrl, { responseType: 'blob' });
      })
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${docId}-${fileName}.${format}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        openSnackbar('success', 'Document downloaded successfully');
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
      });
  };

  const storeSummaryEntityCount = (entityCount) => {
    dispatch(setSummaryEntityCount(entityCount));
  };
  const storeSummaryTableCount = (tableCount) => {
    dispatch(setSummaryTableCount(tableCount));
  };

  const editSummaryEntities = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .put(`${ExtractionService.SUMMARY_KEY_VALUES(applicationId, docId)}`, payload)
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const createSummaryEntities = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .post(`${ExtractionService.SUMMARY_KEY_VALUES(applicationId, docId)}`, payload)
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const markSummaryEntityForReview = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .patch(
        `${ExtractionService.UPDATE_KEY_VALUE_ATTR(applicationId, docId)}`,
        payload,
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deleteSummaryEntities = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .delete(`${ExtractionService.SUMMARY_KEY_VALUES(applicationId, docId)}`, { data: payload })
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const storeEntityDropdownValue = (value) => {
    dispatch(setEntityDropdownSelectedValue(value));
  };

  const resetEntityDropdownValue = () => {
    dispatch(removeEntityDropdownSelectedValue());
  };

  const createSummaryTable = async (
    payload,
  ) => {
    setLoading(true);
    return axiosPrivate
      .post(
        `${ExtractionService.SUMMARY_ADD_TABLE(applicationId, docId)}`,
        payload,
      )
      .then((response) => response.data.data.table_id)
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const addSummaryTableRow = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .post(
        `${ExtractionService.SUMMARY_ADD_ROW(applicationId, docId, tableId)}`,
        payload,
      )
      .then(() => {
        fetchExtractionDetails(currentPage);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  return {
    fetchDocumentExtraction,
    fetchAllBoundingBoxes,
    removeAllBoundingBoxes,
    storeSelectedDocumentId,
    removeDocumentExtraction,
    resetSelectedDocumentId,
    storeCurrentPageNumber,
    resetCurrentPageNumber,
    resetIsDataSkipped,
    loading,
    addKeyValue,
    addTableRows,
    addPaginatedTableRows,
    editEntities,
    editTableRows,
    editPaginatedTableRows,
    deleteEntities,
    deleteTableRows,
    deletePaginatedTableRows,
    editTableColumn,
    deleteTableColumn,
    deletePaginatedTableColumn,
    addTableColumn,
    addPaginatedTableColumn,
    addTable,
    deleteTableCall,
    storeEntityCount,
    storeTableCount,
    exportDocument,
    fetchDocumentSummary,
    storeSummaryEntityCount,
    storeSummaryTableCount,
    editSummaryEntities,
    createSummaryEntities,
    deleteSummaryEntities,
    markSummaryEntityForReview,
    storeEntityDropdownValue,
    resetEntityDropdownValue,
    fetchTableData,
    createSummaryTable,
    addSummaryTableRow,
  };
}

export default useExtraction;
