import React from 'react';
import {
  number, func, bool, string, oneOfType,
} from 'prop-types';
import usePermissions from '../../../hooks/usePermissions';
import classes from './IconButton.module.css';
import { Icon } from '../../atoms/SvgSprites/Icon';

function IconButton(props) {
  const {
    id,
    iconName,
    iconWidth,
    iconHeight,
    iconClasses,
    btnClasses,
    iconColor,
    handleClick,
    isDisabled,
    disabledColor,
    isTimerDependent,
    padding,
    display,
  } = props;
  const { shouldDisableHitl, shouldShowHitl } = usePermissions();

  const shouldDisable = () => {
    if (isTimerDependent) {
      return isDisabled || shouldDisableHitl();
    }
    return isDisabled;
  };

  const shouldShow = () => {
    if (isTimerDependent) {
      return shouldShowHitl();
    }
    return true;
  };

  return (
    shouldShow() ? (
      <button
        id={id}
        type="button"
        className={`${classes.iconButtonStyle} ${btnClasses}`}
        style={{ padding }}
        onClick={handleClick}
        disabled={shouldDisable()}
      >
        <Icon
          name={iconName}
          width={iconWidth}
          height={iconHeight}
          classes={iconClasses}
          color={shouldDisable() ? disabledColor : iconColor}
          display={display}
        />
      </button>
    ) : ('')
  );
}

IconButton.propTypes = {
  id: string,
  iconName: string,
  iconWidth: oneOfType([string, number]),
  iconHeight: oneOfType([string, number]),
  iconClasses: string,
  btnClasses: string,
  iconColor: string,
  handleClick: func,
  isDisabled: bool,
  disabledColor: string,
  isTimerDependent: bool,
  padding: string,
  display: string,
};

IconButton.defaultProps = {
  id: '',
  iconName: '',
  iconWidth: 24,
  iconHeight: 24,
  iconClasses: '',
  btnClasses: '',
  iconColor: 'darkBlue',
  handleClick: () => { },
  isDisabled: false,
  disabledColor: 'midGray',
  isTimerDependent: false,
  padding: '1px 6px',
  display: 'inline-block',
};

export default IconButton;
