import React from 'react';
import {
  string, oneOf, func,
} from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import classes from './Snackbar.module.css';
import { Icon } from '../SvgSprites/Icon';

const snackbarDict = {
  success: {
    title: 'Success',
    iconName: 'success',
    color: 'successGreen',
    class: classes.success,
  },
  error: {
    title: 'An Error Occured',
    iconName: 'error',
    color: 'errorRed',
    class: classes.error,
  },
  info: {
    title: 'Did You Know?',
    iconName: 'info',
    color: 'infoBlue',
    class: classes.info,
  },
  warning: {
    title: 'Warning',
    iconName: 'warning',
    color: 'warning',
    class: classes.warning,
  },
};

// Value of type should be - success, error, info and warning

function Snackbar({
  type, message, handleClose,
}) {
  return (
    <div className={classes.container}>
      <div
        className={classes.snackbar}
        style={{
          borderLeft: `3px solid var(--${snackbarDict[type].color})`,
        }}
      >
        <div className={classes.firstDiv}>
          <Icon
            name={snackbarDict[type].iconName}
            classes={snackbarDict[type].class}
            color={snackbarDict[type].color}
            height={21}
            width={21}
          />
          <div className={classes.text}>
            <div className={`h4Medium ${classes.type}`}>
              {snackbarDict[type].title}
            </div>
            <div className={`h5Regular ${classes.message}`}>
              {message}
            </div>
          </div>
        </div>
        <IconButton size="small" onClick={() => handleClose()}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

Snackbar.propTypes = {
  type: oneOf(['success', 'error', 'info', 'warning']).isRequired,
  message: string.isRequired,
  handleClose: func.isRequired,
};

export default Snackbar;
