import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

// position can be "top", "right", "left" and "bottom" (default)

function CustomizedTooltip(props) {
  const {
    text, children, position, defaultClass,
    showOnClick, // prop to control click behavior
  } = props;

  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    if (showOnClick) {
      setOpen(true);
    }
  };

  const handleTooltipClose = () => {
    if (showOnClick) {
      setOpen(false);
    }
  };

  return (
    <Tooltip
      open={showOnClick ? open : undefined} // Control tooltip open on click if the prop is true
      onClose={handleTooltipClose}
      title={(
        <span className={defaultClass}>{text}</span>
      )}
      placement={position}
      arrow
      disableInteractive={false}
      componentsProps={{
        tooltip: {
          sx: {
            visibility: text === '' ? 'hidden' : '',
            bgcolor: 'var(--darkBlue)',
            '& .MuiTooltip-arrow': {
              color: 'var(--darkBlue)',
            },
          },
        },
        popper: {
          sx: {
            zIndex: `${3000}!important`,
          },
        },
      }}
    >
      <div
        onClick={showOnClick
          ? handleTooltipOpen : undefined} // Only enable click handler if showOnClick is true
        onBlur={showOnClick ? handleTooltipClose : undefined} // Close on blur if clicked
        aria-hidden
      >
        {children}
      </div>
    </Tooltip>
  );
}

CustomizedTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  defaultClass: PropTypes.string,
  showOnClick: PropTypes.bool,
};

CustomizedTooltip.defaultProps = {
  position: 'bottom',
  defaultClass: 'h5Regular',
  showOnClick: false, // Default to false
};

export default CustomizedTooltip;
