// import libraries
import React from 'react';
import { string, func, bool } from 'prop-types';

// import styles
import styles from './Switch.module.css';
import usePermissions from '../../../hooks/usePermissions';

// way to use it
// <Switch handleToggle={handleToggle} isChecked={isChecked} />
function Switch(props) {
  const {
    handleToggle,
    isChecked, isDisabled,
    containerHeight, containerWidth, containerBg, cursor,
    checkedBg,
    switchHeight, switchWidth, switchBg, isTimerDependent,
    isUserHitled,
  } = props;

  const { shouldDisableHitl } = usePermissions();

  const shouldDisable = () => {
    if (isTimerDependent) {
      return isDisabled || shouldDisableHitl();
    }
    return isDisabled;
  };

  return (
    <div
      className={`${styles.switchContainer} ${isChecked ? styles.checked : ''} ${shouldDisable() && styles.disableSwitch}`}
      style={{
        height: containerHeight,
        width: containerWidth,
        backgroundColor: isChecked ? `var(--${checkedBg})` : `var(--${containerBg})`,
        cursor,
      }}
      onClick={() => !shouldDisable() && handleToggle(!isChecked)}
      role="button"
      aria-hidden
      tabIndex={0}
    >
      <div
        className={styles.switchThumb}
        style={{
          height: switchHeight,
          width: switchWidth,
          backgroundColor: `var(--${switchBg})`,
          transform: isChecked ? `translateX(calc(${containerWidth}/1.85))` : '',
        }}
      >
        {
          isUserHitled && (
            <div className={styles.SwitchToggleCircle} />
          )
        }
      </div>
    </div>
  );
}

Switch.propTypes = {
  handleToggle: func.isRequired,
  isChecked: bool.isRequired,
  isDisabled: bool,
  containerHeight: string,
  containerWidth: string,
  containerBg: string,
  cursor: string,
  checkedBg: string,
  switchHeight: string,
  switchWidth: string,
  switchBg: string,
  isTimerDependent: bool,
  isUserHitled: bool,
};
Switch.defaultProps = {
  isDisabled: false,
  containerHeight: '24px',
  containerWidth: '48px',
  containerBg: 'grayDefault',
  cursor: 'pointer',
  checkedBg: 'blueDefault',
  switchHeight: '20px',
  switchWidth: '20px',
  switchBg: 'white',
  isTimerDependent: false,
  isUserHitled: false,
};

export default Switch;
