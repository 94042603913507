import React, {
  createContext, useContext, useMemo,
} from 'react';
import { node } from 'prop-types';
import { Themes } from '../services/constants/constants';
import useLocalStorage from '../hooks/useLocalStorage';

const themeContext = createContext();

export function ThemeContextProvider({ children }) {
  const [theme, setTheme] = useLocalStorage('theme', Themes.LIGHT);

  const toggleTheme = () => {
    if (theme === Themes.LIGHT) {
      setTheme(Themes.DARK);
    } else {
      setTheme(Themes.LIGHT);
    }
  };

  const themeValue = useMemo(() => ({
    theme,
    toggleTheme,
  }), [theme]);

  return (
    <themeContext.Provider value={themeValue}>
      <div data-theme={theme} style={{ height: '100%' }}>
        {children}
      </div>
    </themeContext.Provider>
  );
}

ThemeContextProvider.propTypes = {
  children: node.isRequired,
};

export function useCustomTheme() {
  return useContext(themeContext);
}
