import { ComparisonActionTypes } from '../constants/comparisonActionTypes';

export const setSelectedDocumentIds = (ids) => ({
  type: ComparisonActionTypes.SET_SELECTED_DOCUMENT_IDS,
  payload: ids,
});

export const removeSelectedDocumentIds = () => ({
  type: ComparisonActionTypes.REMOVE_SELECTED_DOCUMENT_IDS,
});

export const setSearchText = (searchText) => ({
  type: ComparisonActionTypes.SET_COMPARISON_SEARCH_TEXT,
  payload: searchText,
});

export const clearSearchText = () => ({
  type: ComparisonActionTypes.CLEAR_COMPARISON_SEARCH_TEXT,
});

export const setComparisonDetails = (comparison) => ({
  type: ComparisonActionTypes.SET_COMPARISON_DETAILS,
  payload: comparison,
});

export const removeComparisonDetails = () => ({
  type: ComparisonActionTypes.REMOVE_COMPARISON_DETAILS,
});

export const resetComparisonState = () => ({
  type: ComparisonActionTypes.RESET_COMPARISON,
});

export const setCmparisonTableWidth = (width) => ({
  type: ComparisonActionTypes.SET_COMPARISON_TABLE_WIDTH,
  payload: width,
});

export const removeCmparisonTableWidth = () => ({
  type: ComparisonActionTypes.REMOVE_COMPARISON_TABLE_WIDTH,
});
