import { combineReducers } from 'redux';
import submissionsReducer from './submissionsReducer';
import timerReducer from './timerReducer';
import extractionReducer from './extractionReducer';
import comparisonReducer from './comparisonReducer';
import applicationReducer from './applicationReducer';
import boundingBoxReducer from './boundingBoxReducer';
import systemReducer from './systemReducer';

const reducers = combineReducers({
  submissions: submissionsReducer,
  timer: timerReducer,
  extraction: extractionReducer,
  comparison: comparisonReducer,
  applications: applicationReducer,
  boundingBox: boundingBoxReducer,
  system: systemReducer,
});

export default reducers;
