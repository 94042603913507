import { TimerActionTypes } from '../constants/timerActionTypes';

const initialState = {
  reviewTime: 0,
  isTimerPaused: true,
  isResetPopupOpen: false,
  isTimeZero: true,
};

const timerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TimerActionTypes.SET_REVIEW_TIME:
      return { ...state, reviewTime: payload };

    case TimerActionTypes.SET_IS_TIMER_PAUSED:
      return { ...state, isTimerPaused: payload };

    case TimerActionTypes.SET_IS_RESET_POPUP_OPEN:
      return { ...state, isResetPopupOpen: payload };

    case TimerActionTypes.SET_IS_TIME_ZERO:
      return { ...state, isTimeZero: payload };

    case TimerActionTypes.RESET_TIMER:
      return initialState;

    default:
      return state;
  }
};

export default timerReducer;
