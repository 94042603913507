import { ApplicationActionTypes } from '../constants/applicationActionTypes';

const initialState = {
  openedApplication: {},
  dualMonitorMode: false,
};

const applicationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ApplicationActionTypes.SET_OPENED_APPLICATION:
      return { ...state, openedApplication: payload };

    case ApplicationActionTypes.REMOVE_OPENED_APPLICATION:
      return { ...state, openedApplication: {} };

    case ApplicationActionTypes.RESET_APPLICATION:
      return initialState;

    case ApplicationActionTypes.SET_DUAL_MONITOR_MODE:
      return { ...state, dualMonitorMode: payload };

    default:
      return state;
  }
};

export default applicationReducer;
